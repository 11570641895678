export class BrowserCache {
  private static instance: BrowserCache;
  private prefix: string;

  private constructor() {
    this.prefix = 'shortdude_cache:';
  }

  public static getInstance(): BrowserCache {
    if (!BrowserCache.instance) {
      BrowserCache.instance = new BrowserCache();
    }
    return BrowserCache.instance;
  }

  public async set(key: string, value: string, ttlSeconds: number): Promise<void> {
    const item = {
      value,
      expires: Date.now() + (ttlSeconds * 1000)
    };
    localStorage.setItem(this.prefix + key, JSON.stringify(item));
  }

  public async get(key: string): Promise<string | null> {
    const item = localStorage.getItem(this.prefix + key);
    if (!item) return null;

    const { value, expires } = JSON.parse(item);
    if (Date.now() > expires) {
      localStorage.removeItem(this.prefix + key);
      return null;
    }

    return value;
  }

  public async del(key: string): Promise<void> {
    localStorage.removeItem(this.prefix + key);
  }

  public async clear(): Promise<void> {
    Object.keys(localStorage)
      .filter(key => key.startsWith(this.prefix))
      .forEach(key => localStorage.removeItem(key));
  }
}