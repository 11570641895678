import React from 'react';

function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm p-8">
        <h1 className="text-3xl font-bold mb-8">Terms of Service</h1>
        
        <div className="space-y-8 text-gray-600">
          {/* No Refund Policy - Important Notice */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">No Refund Policy</h2>
            <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-4">
              <p className="text-red-800 font-medium">
                IMPORTANT: Due to the nature of AI-generated content and API usage, we maintain a strict no-refund policy.
              </p>
              <ul className="list-disc pl-5 mt-2 space-y-1 text-red-800">
                <li>All purchases are final and non-refundable</li>
                <li>This includes subscription payments and credit purchases</li>
                <li>No exceptions will be made for any reason</li>
                <li>By making a purchase, you acknowledge and agree to this policy</li>
              </ul>
            </div>
          </section>

          {/* General Disclaimer - Critical Notice */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">General Disclaimer</h2>
            <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4 mb-4">
              <p className="text-yellow-800 font-bold uppercase mb-2">Use At Your Own Risk</p>
              <p className="text-yellow-800">
                THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. 
                WE DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, RELIABILITY, OR USEFULNESS OF ANY CONTENT GENERATED 
                THROUGH OUR SERVICE.
              </p>
            </div>
          </section>

          {/* Limitation of Liability */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Limitation of Liability</h2>
            <p className="font-medium text-red-600 mb-4">
              IN NO EVENT SHALL SHORTDUDE.COM, ITS OPERATORS, OWNERS, OR AFFILIATES BE LIABLE FOR ANY DAMAGES 
              WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, 
              LOSS OF INFORMATION) ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICE, EVEN IF WE HAVE 
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>We are not liable for any content generated through our service</li>
              <li>We are not responsible for any business decisions made based on our service</li>
              <li>We are not liable for any technical issues or service interruptions</li>
              <li>We are not responsible for any third-party services integrated with our platform</li>
            </ul>
          </section>

          {/* Content Responsibility */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Content Responsibility</h2>
            <p className="mb-4">Users are solely responsible for:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>All content generated using our service</li>
              <li>Ensuring compliance with applicable laws and regulations</li>
              <li>Obtaining necessary rights and permissions for content use</li>
              <li>Any consequences resulting from content distribution</li>
            </ul>
          </section>

          {/* Service Availability */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Service Availability</h2>
            <p className="mb-4">
              We make no guarantees regarding:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Service uptime or availability</li>
              <li>Speed or performance of the service</li>
              <li>Continuous access to the service</li>
              <li>Backup or retention of user data</li>
            </ul>
          </section>

          {/* API and Third-Party Services */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">API and Third-Party Services</h2>
            <p className="mb-4">
              We are not responsible for:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Third-party API availability or functionality</li>
              <li>Changes in third-party service terms or pricing</li>
              <li>Quality or accuracy of third-party data</li>
              <li>Integration issues with external services</li>
            </ul>
          </section>

          {/* Indemnification */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Indemnification</h2>
            <p className="mb-4">
              You agree to indemnify and hold harmless ShortDude.com and its affiliates from any claims, 
              damages, losses, liabilities, costs, and expenses (including legal fees) arising from:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Your use of the service</li>
              <li>Your violation of these terms</li>
              <li>Your content generated through our service</li>
              <li>Any claims made by third parties relating to your use of the service</li>
            </ul>
          </section>

          {/* Account Security */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Account Security</h2>
            <p className="mb-4">Users are solely responsible for:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Maintaining account security</li>
              <li>All activities occurring under their account</li>
              <li>Unauthorized access to their account</li>
              <li>Any losses resulting from compromised account credentials</li>
            </ul>
          </section>

          {/* Termination */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Termination</h2>
            <p className="mb-4">
              We reserve the right to:
            </p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Terminate or suspend accounts without prior notice</li>
              <li>Remove or disable access to any content</li>
              <li>Modify or discontinue the service at any time</li>
              <li>Refuse service to anyone for any reason</li>
            </ul>
          </section>

          {/* Changes to Terms */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Changes to Terms</h2>
            <p>
              We reserve the right to modify these terms at any time without notice. Continued use of 
              the service constitutes acceptance of modified terms.
            </p>
          </section>

          {/* Governing Law */}
          <section>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Governing Law</h2>
            <p>
              These terms shall be governed by and construed in accordance with the laws of the jurisdiction 
              in which we operate, without regard to its conflict of law provisions.
            </p>
          </section>

          <div className="mt-8 pt-8 border-t border-gray-200">
            <p className="text-sm text-gray-500">Last updated: March 15, 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;