import { z } from 'zod';
import { BrowserCache } from '../cache/BrowserCache';

const CACHE_KEY_PREFIX = 'crypto_price:';
const CACHE_TTL = 300; // 5 minutes in seconds
const CMC_API_KEY = 'c7f6b863-fa54-4c3e-a96d-6329cbf0601a';

// Using a CORS proxy to handle the requests
const PROXY_URL = 'https://api.allorigins.win/raw?url=';
const CMC_API_URL = 'https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes/latest';

const COIN_IDS = {
  bitcoin: '1',
  ethereum: '1027',
  solana: '5426'
};

// Fallback prices in case API fails
const FALLBACK_PRICES = {
  bitcoin: 65000,
  ethereum: 3500,
  solana: 100
};

const PriceResponseSchema = z.object({
  data: z.record(z.object({
    quote: z.object({
      USD: z.object({
        price: z.number()
      })
    })
  }))
});

export class CryptoPriceService {
  private static instance: CryptoPriceService;
  private cache: BrowserCache;
  private lastUpdate: Record<string, number> = {};

  private constructor() {
    this.cache = BrowserCache.getInstance();
  }

  public static getInstance(): CryptoPriceService {
    if (!CryptoPriceService.instance) {
      CryptoPriceService.instance = new CryptoPriceService();
    }
    return CryptoPriceService.instance;
  }

  public async getPrice(currency: string): Promise<number> {
    try {
      // Try to get price from cache first
      const cachedPrice = await this.cache.get(`${CACHE_KEY_PREFIX}${currency}`);
      if (cachedPrice) {
        return parseFloat(cachedPrice);
      }

      // If not in cache, fetch new price
      const price = await this.fetchPrice(currency);
      await this.cachePrice(currency, price);
      return price;
    } catch (error) {
      console.warn('Error fetching crypto price:', { currency, error });
      // Return fallback price if API fails
      return FALLBACK_PRICES[currency as keyof typeof FALLBACK_PRICES] || 0;
    }
  }

  private async fetchPrice(currency: string): Promise<number> {
    const id = COIN_IDS[currency as keyof typeof COIN_IDS];
    if (!id) throw new Error(`Unsupported currency: ${currency}`);

    try {
      const encodedUrl = encodeURIComponent(`${CMC_API_URL}?id=${id}`);
      const response = await fetch(`${PROXY_URL}${encodedUrl}`, {
        headers: {
          'X-CMC_PRO_API_KEY': CMC_API_KEY,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`CoinMarketCap API error: ${response.status}`);
      }

      const data = await response.json();
      const validated = PriceResponseSchema.parse(data);
      const price = validated.data[id].quote.USD.price;

      this.lastUpdate[currency] = Date.now();
      return price;
    } catch (error) {
      console.warn('Failed to fetch price from CoinMarketCap:', error);
      // Return fallback price if API fails
      return FALLBACK_PRICES[currency as keyof typeof FALLBACK_PRICES];
    }
  }

  private async cachePrice(currency: string, price: number): Promise<void> {
    try {
      await this.cache.set(
        `${CACHE_KEY_PREFIX}${currency}`,
        price.toString(),
        CACHE_TTL
      );
    } catch (error) {
      console.warn('Failed to cache price:', { currency, price, error });
    }
  }

  public async calculateCryptoAmount(usdAmount: number, currency: string): Promise<number> {
    const price = await this.getPrice(currency);
    return price > 0 ? usdAmount / price : 0;
  }
}