import React from 'react';
import { Bell, Mail, MessageSquare, Settings } from 'lucide-react';

export default function NotificationManager() {
  const notifications = [
    { id: 1, type: 'user', message: 'New user registration', time: '2 minutes ago', read: false },
    { id: 2, type: 'payment', message: 'Payment received', time: '1 hour ago', read: true },
    { id: 3, type: 'system', message: 'System update completed', time: '2 hours ago', read: true }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Notification Manager</h1>
        <button className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">
          <Settings className="w-4 h-4" />
          Configure
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center gap-3 mb-4">
            <Bell className="w-5 h-5 text-indigo-600" />
            <h2 className="text-lg font-semibold">App Notifications</h2>
          </div>
          <label className="flex items-center justify-between mb-4">
            <span className="text-sm font-medium">New User Registrations</span>
            <input type="checkbox" className="rounded text-indigo-600" defaultChecked />
          </label>
          <label className="flex items-center justify-between mb-4">
            <span className="text-sm font-medium">Payment Alerts</span>
            <input type="checkbox" className="rounded text-indigo-600" defaultChecked />
          </label>
          <label className="flex items-center justify-between">
            <span className="text-sm font-medium">System Updates</span>
            <input type="checkbox" className="rounded text-indigo-600" defaultChecked />
          </label>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center gap-3 mb-4">
            <Mail className="w-5 h-5 text-indigo-600" />
            <h2 className="text-lg font-semibold">Email Notifications</h2>
          </div>
          <label className="flex items-center justify-between mb-4">
            <span className="text-sm font-medium">Daily Summary</span>
            <input type="checkbox" className="rounded text-indigo-600" defaultChecked />
          </label>
          <label className="flex items-center justify-between mb-4">
            <span className="text-sm font-medium">Weekly Reports</span>
            <input type="checkbox" className="rounded text-indigo-600" defaultChecked />
          </label>
          <label className="flex items-center justify-between">
            <span className="text-sm font-medium">Security Alerts</span>
            <input type="checkbox" className="rounded text-indigo-600" defaultChecked />
          </label>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center gap-3 mb-4">
            <MessageSquare className="w-5 h-5 text-indigo-600" />
            <h2 className="text-lg font-semibold">SMS Notifications</h2>
          </div>
          <label className="flex items-center justify-between mb-4">
            <span className="text-sm font-medium">Critical Alerts</span>
            <input type="checkbox" className="rounded text-indigo-600" />
          </label>
          <label className="flex items-center justify-between mb-4">
            <span className="text-sm font-medium">Payment Failures</span>
            <input type="checkbox" className="rounded text-indigo-600" />
          </label>
          <label className="flex items-center justify-between">
            <span className="text-sm font-medium">System Downtime</span>
            <input type="checkbox" className="rounded text-indigo-600" />
          </label>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-6 border-b">
          <h2 className="text-lg font-semibold">Recent Notifications</h2>
        </div>
        <div className="divide-y">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`p-4 flex items-center justify-between ${
                notification.read ? 'bg-white' : 'bg-indigo-50'
              }`}
            >
              <div className="flex items-center gap-4">
                <div className={`w-2 h-2 rounded-full ${
                  notification.read ? 'bg-gray-300' : 'bg-indigo-600'
                }`} />
                <div>
                  <p className="text-sm font-medium text-gray-900">{notification.message}</p>
                  <p className="text-sm text-gray-500">{notification.time}</p>
                </div>
              </div>
              <button className="text-sm text-indigo-600 hover:text-indigo-900">
                Mark as read
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}