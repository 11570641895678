import React, { useState } from 'react';
import { Plus, Trash2, Save, MoveVertical } from 'lucide-react';
import toast from 'react-hot-toast';
import { useFooter } from '../../context/FooterContext';

interface FooterLink {
  id: string;
  text: string;
  url: string;
  column: 'company' | 'legal' | 'resources' | 'social';
  order: number;
}

function FooterManager() {
  const { footerLinks, updateFooterLinks } = useFooter();
  const [links, setLinks] = useState<FooterLink[]>(footerLinks);
  const [newLink, setNewLink] = useState<Partial<FooterLink>>({
    text: '',
    url: '',
    column: 'company'
  });

  const columns = [
    { id: 'company', label: 'Company' },
    { id: 'legal', label: 'Legal' },
    { id: 'resources', label: 'Resources' },
    { id: 'social', label: 'Social' }
  ];

  const handleAddLink = () => {
    if (!newLink.text || !newLink.url || !newLink.column) {
      toast.error('Please fill in all fields');
      return;
    }

    const maxOrder = Math.max(...links.map(link => link.order), 0);
    const newLinkComplete: FooterLink = {
      id: crypto.randomUUID(),
      text: newLink.text,
      url: newLink.url,
      column: newLink.column as FooterLink['column'],
      order: maxOrder + 1
    };

    setLinks([...links, newLinkComplete]);
    setNewLink({ text: '', url: '', column: 'company' });
    toast.success('Link added successfully');
  };

  const handleDeleteLink = (id: string) => {
    setLinks(links.filter(link => link.id !== id));
    toast.success('Link deleted successfully');
  };

  const handleMoveLink = (id: string, direction: 'up' | 'down') => {
    const index = links.findIndex(link => link.id === id);
    if (index === -1) return;

    const newLinks = [...links];
    const currentLink = newLinks[index];
    const adjacentLink = newLinks[direction === 'up' ? index - 1 : index + 1];

    if (!adjacentLink) return;

    // Swap orders
    const tempOrder = currentLink.order;
    currentLink.order = adjacentLink.order;
    adjacentLink.order = tempOrder;

    // Sort by order
    newLinks.sort((a, b) => a.order - b.order);
    setLinks(newLinks);
  };

  const handleSave = async () => {
    try {
      await updateFooterLinks(links);
      toast.success('Footer links updated successfully');
    } catch (error) {
      toast.error('Failed to update footer links');
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Footer Management</h1>
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
        >
          <Save className="w-4 h-4 mr-2" />
          Save Changes
        </button>
      </div>

      {/* Add New Link Form */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Add New Link</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Link Text"
            className="rounded-md border-gray-300"
            value={newLink.text}
            onChange={(e) => setNewLink({ ...newLink, text: e.target.value })}
          />
          <input
            type="text"
            placeholder="URL"
            className="rounded-md border-gray-300"
            value={newLink.url}
            onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
          />
          <select
            className="rounded-md border-gray-300"
            value={newLink.column}
            onChange={(e) => setNewLink({ ...newLink, column: e.target.value as FooterLink['column'] })}
          >
            {columns.map(column => (
              <option key={column.id} value={column.id}>
                {column.label}
              </option>
            ))}
          </select>
          <button
            onClick={handleAddLink}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center justify-center"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Link
          </button>
        </div>
      </div>

      {/* Footer Preview */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Footer Links</h2>
        <div className="grid md:grid-cols-4 gap-8">
          {columns.map(column => (
            <div key={column.id}>
              <h3 className="font-semibold text-gray-900 mb-4">{column.label}</h3>
              <ul className="space-y-2">
                {links
                  .filter(link => link.column === column.id)
                  .sort((a, b) => a.order - b.order)
                  .map(link => (
                    <li
                      key={link.id}
                      className="flex items-center justify-between bg-gray-50 p-2 rounded"
                    >
                      <span className="text-sm truncate flex-1">{link.text}</span>
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => handleMoveLink(link.id, 'up')}
                          className="p-1 hover:bg-gray-200 rounded"
                          title="Move up"
                        >
                          <MoveVertical className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleDeleteLink(link.id)}
                          className="p-1 text-red-600 hover:bg-red-50 rounded"
                          title="Delete"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FooterManager;