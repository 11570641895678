import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

function RequireAdmin() {
  const { currentUser, isAdmin, loading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    console.log('RequireAdmin:', { currentUser: !!currentUser, isAdmin, loading });
  }, [currentUser, isAdmin, loading]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-dark">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!currentUser || !isAdmin) {
    console.log('Redirecting to admin login');
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  console.log('Rendering admin layout');
  return <Outlet />;
}

export default RequireAdmin;