import React, { useState, useEffect } from 'react';
import { CreditCard, Bitcoin, Wallet } from 'lucide-react';
import toast from 'react-hot-toast';
import { CryptoPriceService } from '../services/crypto/PriceService';
import { StripeService } from '../services/payment/StripeService';
import { usePayment } from '../context/PaymentContext';
import { useAuth } from '../context/AuthContext';

interface CryptoPrice {
  bitcoin: number;
  ethereum: number;
  solana: number;
}

const Pricing: React.FC = () => {
  const { isCryptoEnabled } = usePayment();
  const { currentUser } = useAuth();
  const [cryptoPrices, setCryptoPrices] = useState<CryptoPrice>({
    bitcoin: 0,
    ethereum: 0,
    solana: 0
  });
  const [loading, setLoading] = useState(false);
  const basePrice = 47;
  const cryptoDiscount = 0.15; // 15% discount for crypto payments

  const priceService = CryptoPriceService.getInstance();
  const stripeService = StripeService.getInstance();

  useEffect(() => {
    let mounted = true;
    let interval: NodeJS.Timeout;

    const fetchPrices = async () => {
      if (!isCryptoEnabled()) return;

      try {
        const [btc, eth, sol] = await Promise.all([
          priceService.getPrice('bitcoin'),
          priceService.getPrice('ethereum'),
          priceService.getPrice('solana')
        ]);

        if (mounted) {
          setCryptoPrices({
            bitcoin: btc,
            ethereum: eth,
            solana: sol
          });
        }
      } catch (error) {
        console.warn('Error fetching crypto prices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrices();
    if (isCryptoEnabled()) {
      interval = setInterval(fetchPrices, 5 * 60 * 1000); // Refresh every 5 minutes
    }

    return () => {
      mounted = false;
      if (interval) clearInterval(interval);
    };
  }, [isCryptoEnabled()]);

  const calculateCryptoAmount = (cryptoPrice: number) => {
    if (!cryptoPrice || cryptoPrice <= 0) return '0';
    const discountedPrice = basePrice * (1 - cryptoDiscount);
    return (discountedPrice / cryptoPrice).toFixed(8);
  };

  const handleCardPayment = async () => {
    if (!currentUser) {
      toast.error('Please sign in to continue');
      return;
    }

    setLoading(true);
    try {
      await stripeService.createSubscription('price_monthly');
      // Redirect will happen automatically
    } catch (error) {
      toast.error('Payment failed. Please try again.');
      setLoading(false);
    }
  };

  const handleCryptoPayment = () => {
    if (!currentUser) {
      toast.error('Please sign in to continue');
      return;
    }

    if (!isCryptoEnabled()) {
      toast.error('Cryptocurrency payments are currently not available');
      return;
    }
    // Process crypto payment
    toast.success('Processing crypto payment...');
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Simple, Transparent Pricing</h1>
          <p className="text-xl text-gray-600">Choose the plan that's right for you</p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {/* Free Plan */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="p-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-2">Free Plan</h2>
              <p className="text-gray-600 mb-6">Perfect for trying out our service</p>
              <div className="mb-8">
                <p className="text-4xl font-bold text-gray-900">$0</p>
                <p className="text-gray-600">Forever free</p>
              </div>
              <button 
                onClick={() => toast.success('Starting free trial...')}
                className="w-full px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
              >
                Start Free Trial
              </button>
            </div>
          </div>

          {/* Pro Plan */}
          <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-2xl shadow-xl overflow-hidden text-white">
            <div className="p-8">
              <h2 className="text-2xl font-semibold mb-2">Pro Plan</h2>
              <p className="opacity-90 mb-6">For professional content creators</p>
              <div className="mb-8">
                <p className="text-4xl font-bold">${basePrice}</p>
                <p className="opacity-90">per month</p>
              </div>

              <div className="space-y-4">
                <button
                  onClick={handleCardPayment}
                  disabled={loading}
                  className="w-full flex items-center justify-center px-4 py-2 bg-white text-indigo-600 rounded-md hover:bg-gray-100 disabled:opacity-50"
                >
                  {loading ? (
                    <div className="flex items-center">
                      <div className="w-5 h-5 border-t-2 border-b-2 border-indigo-600 rounded-full animate-spin mr-2" />
                      Processing...
                    </div>
                  ) : (
                    <>
                      <CreditCard className="w-5 h-5 mr-2" />
                      Subscribe with Card
                    </>
                  )}
                </button>

                {isCryptoEnabled() && (
                  <>
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-white opacity-20" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-transparent text-white opacity-90">
                          Or pay with crypto (15% off)
                        </span>
                      </div>
                    </div>

                    <button
                      onClick={handleCryptoPayment}
                      className="w-full flex items-center justify-center px-4 py-2 bg-white/10 text-white rounded-md hover:bg-white/20"
                    >
                      <Bitcoin className="w-5 h-5 mr-2" />
                      Pay {calculateCryptoAmount(cryptoPrices.bitcoin)} BTC
                    </button>

                    <button
                      onClick={handleCryptoPayment}
                      className="w-full flex items-center justify-center px-4 py-2 bg-white/10 text-white rounded-md hover:bg-white/20"
                    >
                      <Wallet className="w-5 h-5 mr-2" />
                      Pay {calculateCryptoAmount(cryptoPrices.ethereum)} ETH
                    </button>

                    <button
                      onClick={handleCryptoPayment}
                      className="w-full flex items-center justify-center px-4 py-2 bg-white/10 text-white rounded-md hover:bg-white/20"
                    >
                      <Wallet className="w-5 h-5 mr-2" />
                      Pay {calculateCryptoAmount(cryptoPrices.solana)} SOL
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-600">
            Need more credits? Additional credits available at $10 per 120 credits
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pricing;