import { collection, query, where, orderBy, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../config/firebase';

export interface ContentItem {
  id: string;
  title: string;
  type: 'video' | 'article';
  status: 'draft' | 'published' | 'archived';
  author: string;
  date: string;
  url?: string;
  thumbnail?: string;
  description?: string;
  views: number;
  duration?: number;
}

export class ContentService {
  private static instance: ContentService;

  private constructor() {}

  public static getInstance(): ContentService {
    if (!ContentService.instance) {
      ContentService.instance = new ContentService();
    }
    return ContentService.instance;
  }

  public async getContent(filters: {
    search?: string;
    type?: string;
    status?: string;
  }): Promise<ContentItem[]> {
    try {
      let q = query(collection(db, 'content'), orderBy('date', 'desc'));

      if (filters.type && filters.type !== 'all') {
        q = query(q, where('type', '==', filters.type));
      }

      if (filters.status && filters.status !== 'all') {
        q = query(q, where('status', '==', filters.status));
      }

      const snapshot = await getDocs(q);
      const content = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as ContentItem[];

      if (filters.search) {
        const searchTerm = filters.search.toLowerCase();
        return content.filter(item =>
          item.title.toLowerCase().includes(searchTerm) ||
          item.description?.toLowerCase().includes(searchTerm)
        );
      }

      return content;
    } catch (error) {
      console.error('Error fetching content:', error);
      throw new Error('Failed to fetch content');
    }
  }

  public async uploadVideo(file: File, metadata: Partial<ContentItem>): Promise<ContentItem> {
    try {
      const storageRef = ref(storage, `content/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload progress:', progress);
          },
          (error) => {
            console.error('Upload error:', error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              
              // Generate thumbnail
              const thumbnailUrl = await this.generateThumbnail(file);

              const contentData = {
                ...metadata,
                url: downloadURL,
                thumbnail: thumbnailUrl,
                date: new Date().toISOString(),
                views: 0
              };

              const docRef = await addDoc(collection(db, 'content'), contentData);
              resolve({
                id: docRef.id,
                ...contentData
              } as ContentItem);
            } catch (error) {
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.error('Upload error:', error);
      throw new Error('Failed to upload video');
    }
  }

  private async generateThumbnail(videoFile: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      video.onloadedmetadata = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      };

      video.onseeked = () => {
        if (context) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(async (blob) => {
            if (blob) {
              const thumbnailRef = ref(storage, `thumbnails/${Date.now()}_thumbnail.jpg`);
              await uploadBytesResumable(thumbnailRef, blob);
              const thumbnailUrl = await getDownloadURL(thumbnailRef);
              resolve(thumbnailUrl);
            } else {
              reject(new Error('Failed to generate thumbnail'));
            }
          }, 'image/jpeg', 0.7);
        }
      };

      video.onerror = () => {
        reject(new Error('Error loading video'));
      };

      video.src = URL.createObjectURL(videoFile);
      // Seek to 25% of the video duration for thumbnail
      video.currentTime = 1;
    });
  }

  public async deleteContent(contentId: string): Promise<void> {
    try {
      const contentRef = doc(db, 'content', contentId);
      const contentData = (await getDocs(query(collection(db, 'content'), where('id', '==', contentId)))).docs[0].data() as ContentItem;

      // Delete video file
      if (contentData.url) {
        const videoRef = ref(storage, contentData.url);
        await deleteObject(videoRef);
      }

      // Delete thumbnail
      if (contentData.thumbnail) {
        const thumbnailRef = ref(storage, contentData.thumbnail);
        await deleteObject(thumbnailRef);
      }

      // Delete document
      await deleteDoc(contentRef);
    } catch (error) {
      console.error('Delete error:', error);
      throw new Error('Failed to delete content');
    }
  }

  public async updateContent(contentId: string, updates: Partial<ContentItem>): Promise<void> {
    try {
      const contentRef = doc(db, 'content', contentId);
      await updateDoc(contentRef, updates);
    } catch (error) {
      console.error('Update error:', error);
      throw new Error('Failed to update content');
    }
  }

  public async incrementViews(contentId: string): Promise<void> {
    try {
      const contentRef = doc(db, 'content', contentId);
      await updateDoc(contentRef, {
        views: increment(1)
      });
    } catch (error) {
      console.error('View increment error:', error);
      throw new Error('Failed to increment views');
    }
  }
}

export const contentService = ContentService.getInstance();