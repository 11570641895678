import React, { createContext, useContext, useState, useEffect } from 'react';
import { BrowserCache } from '../services/cache/BrowserCache';

interface FooterLink {
  id: string;
  text: string;
  url: string;
  column: 'company' | 'legal' | 'resources' | 'social';
  order: number;
}

interface FooterContextType {
  footerLinks: FooterLink[];
  updateFooterLinks: (links: FooterLink[]) => Promise<void>;
}

const FooterContext = createContext<FooterContextType>({
  footerLinks: [],
  updateFooterLinks: async () => {}
});

export const useFooter = () => useContext(FooterContext);

const defaultFooterLinks: FooterLink[] = [
  { id: '1', text: 'About Us', url: '/about', column: 'company', order: 1 },
  { id: '2', text: 'Contact', url: '/contact', column: 'company', order: 2 },
  { id: '3', text: 'Privacy Policy', url: '/privacy', column: 'legal', order: 1 },
  { id: '4', text: 'Terms of Service', url: '/terms', column: 'legal', order: 2 },
  { id: '5', text: 'Help Center', url: '/help', column: 'resources', order: 1 },
  { id: '6', text: 'Blog', url: '/blog', column: 'resources', order: 2 },
  { id: '7', text: 'Twitter', url: 'https://twitter.com', column: 'social', order: 1 },
  { id: '8', text: 'LinkedIn', url: 'https://linkedin.com', column: 'social', order: 2 }
];

export const FooterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [footerLinks, setFooterLinks] = useState<FooterLink[]>(defaultFooterLinks);
  const cache = BrowserCache.getInstance();

  useEffect(() => {
    const loadFooterLinks = async () => {
      const savedLinks = await cache.get('footer_links');
      if (savedLinks) {
        setFooterLinks(JSON.parse(savedLinks));
      }
    };
    loadFooterLinks();
  }, []);

  const updateFooterLinks = async (links: FooterLink[]) => {
    setFooterLinks(links);
    await cache.set('footer_links', JSON.stringify(links), 86400); // 24 hours TTL
  };

  return (
    <FooterContext.Provider value={{ footerLinks, updateFooterLinks }}>
      {children}
    </FooterContext.Provider>
  );
};