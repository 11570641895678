import React, { useState, useEffect } from 'react';
import { Save } from 'lucide-react';
import toast from 'react-hot-toast';

interface ThemeColors {
  background: string;
  paper: string;
  content: string;
  border: string;
  textPrimary: string;
  textSecondary: string;
  textMuted: string;
}

interface ThemeSettings {
  light: ThemeColors;
  dark: ThemeColors;
}

const defaultTheme: ThemeSettings = {
  light: {
    background: '#ffffff',
    paper: '#ffffff',
    content: '#f9fafb',
    border: '#e5e7eb',
    textPrimary: '#111827',
    textSecondary: '#4b5563',
    textMuted: '#6b7280'
  },
  dark: {
    background: '#111827',
    paper: '#1f2937',
    content: '#374151',
    border: '#4b5563',
    textPrimary: '#f9fafb',
    textSecondary: '#e5e7eb',
    textMuted: '#9ca3af'
  }
};

function ThemeManager() {
  const [theme, setTheme] = useState<ThemeSettings>(defaultTheme);
  const [activeMode, setActiveMode] = useState<'light' | 'dark'>('light');
  const [selectedProperty, setSelectedProperty] = useState<keyof ThemeColors | null>(null);

  useEffect(() => {
    // Load saved theme from localStorage
    const savedTheme = localStorage.getItem('admin_theme');
    if (savedTheme) {
      setTheme(JSON.parse(savedTheme));
    }
  }, []);

  const handleColorChange = (property: keyof ThemeColors, color: string) => {
    setTheme(prev => ({
      ...prev,
      [activeMode]: {
        ...prev[activeMode],
        [property]: color
      }
    }));
  };

  const handleSave = () => {
    try {
      // Save to localStorage
      localStorage.setItem('admin_theme', JSON.stringify(theme));

      // Update CSS variables
      const root = document.documentElement;
      Object.entries(theme[activeMode]).forEach(([key, value]) => {
        root.style.setProperty(`--${key}`, value);
      });

      toast.success('Theme settings saved successfully');
    } catch (error) {
      toast.error('Failed to save theme settings');
    }
  };

  const handleReset = () => {
    setTheme(defaultTheme);
    toast.success('Theme reset to defaults');
  };

  const ColorPicker = ({ property, label }: { property: keyof ThemeColors; label: string }) => (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
        {label}
      </label>
      <div className="flex items-center gap-2">
        <input
          type="color"
          className="w-10 h-10 p-0 border-0 rounded cursor-pointer"
          value={theme[activeMode][property]}
          onChange={(e) => handleColorChange(property, e.target.value)}
        />
        <input
          type="text"
          className="flex-1 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
          value={theme[activeMode][property]}
          onChange={(e) => handleColorChange(property, e.target.value)}
        />
      </div>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold">Theme Manager</h1>
          <p className="text-gray-600 dark:text-gray-300 mt-2">
            Customize the appearance of your application
          </p>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleReset}
            className="px-4 py-2 text-gray-700 bg-white border rounded-md hover:bg-gray-50"
          >
            Reset to Default
          </button>
          <button
            onClick={handleSave}
            className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            <Save className="w-4 h-4 mr-2" />
            Save Changes
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Mode Selector */}
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex justify-center space-x-4 mb-6">
            <button
              onClick={() => setActiveMode('light')}
              className={`px-4 py-2 rounded-md ${
                activeMode === 'light'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'bg-gray-100 text-gray-700'
              }`}
            >
              Light Mode
            </button>
            <button
              onClick={() => setActiveMode('dark')}
              className={`px-4 py-2 rounded-md ${
                activeMode === 'dark'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'bg-gray-100 text-gray-700'
              }`}
            >
              Dark Mode
            </button>
          </div>

          <div className="space-y-4">
            <ColorPicker property="background" label="Background Color" />
            <ColorPicker property="paper" label="Paper/Card Color" />
            <ColorPicker property="content" label="Content Area Color" />
            <ColorPicker property="border" label="Border Color" />
            <ColorPicker property="textPrimary" label="Primary Text Color" />
            <ColorPicker property="textSecondary" label="Secondary Text Color" />
            <ColorPicker property="textMuted" label="Muted Text Color" />
          </div>
        </div>

        {/* Preview Panel */}
        <div className="space-y-6">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Live Preview</h2>
            <div
              className="p-4 rounded-lg"
              style={{ backgroundColor: theme[activeMode].background }}
            >
              <div
                className="p-4 rounded-lg mb-4"
                style={{
                  backgroundColor: theme[activeMode].paper,
                  borderColor: theme[activeMode].border,
                  borderWidth: 1
                }}
              >
                <h3
                  style={{ color: theme[activeMode].textPrimary }}
                  className="text-lg font-semibold mb-2"
                >
                  Sample Card
                </h3>
                <p style={{ color: theme[activeMode].textSecondary }}>
                  This is how your content will look with the selected colors.
                </p>
                <p
                  style={{ color: theme[activeMode].textMuted }}
                  className="text-sm mt-2"
                >
                  Additional information in muted text
                </p>
              </div>

              <div
                className="p-4 rounded-lg"
                style={{
                  backgroundColor: theme[activeMode].content,
                  borderColor: theme[activeMode].border,
                  borderWidth: 1
                }}
              >
                <h4
                  style={{ color: theme[activeMode].textPrimary }}
                  className="font-medium mb-2"
                >
                  Content Area
                </h4>
                <p style={{ color: theme[activeMode].textSecondary }}>
                  Content areas use a slightly different background color.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThemeManager;