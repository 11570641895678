import React, { createContext, useContext, useState, useEffect } from 'react';
import { BrowserCache } from '../services/cache/BrowserCache';

interface NavLink {
  id: string;
  text: string;
  url: string;
  order: number;
}

interface HeaderSettings {
  logoType: 'text' | 'image';
  logoText: string;
  logoUrl: string;
  logoFont: string;
  primaryColor: string;
  navLinks: NavLink[];
}

interface HeaderContextType {
  headerSettings: HeaderSettings;
  updateHeaderSettings: (settings: HeaderSettings) => Promise<void>;
}

const defaultSettings: HeaderSettings = {
  logoType: 'text',
  logoText: 'ShortDude',
  logoUrl: '',
  logoFont: 'sans',
  primaryColor: '#4f46e5',
  navLinks: [
    { id: '1', text: 'Pricing', url: '/pricing', order: 1 },
    { id: '2', text: 'Features', url: '/features', order: 2 },
    { id: '3', text: 'Blog', url: '/blog', order: 3 }
  ]
};

const HeaderContext = createContext<HeaderContextType>({
  headerSettings: defaultSettings,
  updateHeaderSettings: async () => {}
});

export const useHeader = () => useContext(HeaderContext);

export function HeaderProvider({ children }: { children: React.ReactNode }) {
  const [headerSettings, setHeaderSettings] = useState<HeaderSettings>(defaultSettings);
  const cache = BrowserCache.getInstance();

  useEffect(() => {
    const loadSettings = async () => {
      const savedSettings = await cache.get('header_settings');
      if (savedSettings) {
        setHeaderSettings(JSON.parse(savedSettings));
      }
    };
    loadSettings();
  }, []);

  const updateHeaderSettings = async (settings: HeaderSettings) => {
    setHeaderSettings(settings);
    await cache.set('header_settings', JSON.stringify(settings), 86400); // 24 hours TTL
  };

  return (
    <HeaderContext.Provider value={{ headerSettings, updateHeaderSettings }}>
      {children}
    </HeaderContext.Provider>
  );
}

export default HeaderProvider;