import React, { useState, useEffect } from 'react';
import { Save, Eye, Code, Layout, Plus, Trash2, ArrowUp, ArrowDown } from 'lucide-react';
import { Editor } from '@tinymce/tinymce-react';
import toast from 'react-hot-toast';

interface PageSection {
  id: string;
  type: 'hero' | 'features' | 'cta' | 'content' | 'pricing';
  content: any;
  order: number;
}

interface Page {
  id: string;
  title: string;
  slug: string;
  sections: PageSection[];
  seo: {
    title: string;
    description: string;
    keywords: string;
  };
}

export default function PageEditor() {
  const [pages, setPages] = useState<Page[]>([]);
  const [selectedPage, setSelectedPage] = useState<Page | null>(null);
  const [editMode, setEditMode] = useState<'visual' | 'code'>('visual');
  const [previewMode, setPreviewMode] = useState(false);
  const [saving, setSaving] = useState(false);

  // Load available pages
  useEffect(() => {
    // In a real app, fetch pages from your backend
    const availablePages = [
      { id: '1', title: 'Home', slug: '/' },
      { id: '2', title: 'Features', slug: '/features' },
      { id: '3', title: 'Pricing', slug: '/pricing' },
      { id: '4', title: 'Privacy Policy', slug: '/privacy' },
      { id: '5', title: 'Terms of Service', slug: '/terms' }
    ];
    setPages(availablePages);
  }, []);

  const handlePageSelect = async (pageId: string) => {
    // In a real app, fetch page content from your backend
    // For now, we'll use mock data
    const page = {
      id: pageId,
      title: pages.find(p => p.id === pageId)?.title || '',
      slug: pages.find(p => p.id === pageId)?.slug || '',
      sections: [],
      seo: {
        title: '',
        description: '',
        keywords: ''
      }
    };
    setSelectedPage(page);
  };

  const handleSectionAdd = (type: PageSection['type']) => {
    if (!selectedPage) return;

    const newSection: PageSection = {
      id: crypto.randomUUID(),
      type,
      content: {},
      order: selectedPage.sections.length
    };

    setSelectedPage({
      ...selectedPage,
      sections: [...selectedPage.sections, newSection]
    });
  };

  const handleSectionMove = (sectionId: string, direction: 'up' | 'down') => {
    if (!selectedPage) return;

    const sectionIndex = selectedPage.sections.findIndex(s => s.id === sectionId);
    if (sectionIndex === -1) return;

    const newSections = [...selectedPage.sections];
    const section = newSections[sectionIndex];
    
    if (direction === 'up' && sectionIndex > 0) {
      newSections[sectionIndex] = newSections[sectionIndex - 1];
      newSections[sectionIndex - 1] = section;
    } else if (direction === 'down' && sectionIndex < newSections.length - 1) {
      newSections[sectionIndex] = newSections[sectionIndex + 1];
      newSections[sectionIndex + 1] = section;
    }

    setSelectedPage({
      ...selectedPage,
      sections: newSections
    });
  };

  const handleSectionDelete = (sectionId: string) => {
    if (!selectedPage) return;

    setSelectedPage({
      ...selectedPage,
      sections: selectedPage.sections.filter(s => s.id !== sectionId)
    });
  };

  const handleSave = async () => {
    if (!selectedPage) return;

    setSaving(true);
    try {
      // In a real app, save to your backend
      await new Promise(resolve => setTimeout(resolve, 1000));
      toast.success('Page saved successfully');
    } catch (error) {
      toast.error('Failed to save page');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold">Page Editor</h1>
          <select 
            className="rounded-md border-gray-300"
            onChange={(e) => handlePageSelect(e.target.value)}
            value={selectedPage?.id || ''}
          >
            <option value="">Select a page</option>
            {pages.map(page => (
              <option key={page.id} value={page.id}>
                {page.title}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center gap-2">
          <div className="flex p-1 bg-gray-100 rounded-md">
            <button
              onClick={() => setEditMode('visual')}
              className={`px-3 py-1 rounded ${
                editMode === 'visual' 
                  ? 'bg-white shadow-sm' 
                  : 'text-gray-600'
              }`}
            >
              <Layout className="w-4 h-4" />
            </button>
            <button
              onClick={() => setEditMode('code')}
              className={`px-3 py-1 rounded ${
                editMode === 'code' 
                  ? 'bg-white shadow-sm' 
                  : 'text-gray-600'
              }`}
            >
              <Code className="w-4 h-4" />
            </button>
          </div>

          <button
            onClick={() => setPreviewMode(!previewMode)}
            className={`p-2 rounded-md ${
              previewMode 
                ? 'bg-indigo-100 text-indigo-600' 
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            <Eye className="w-4 h-4" />
          </button>

          <button
            onClick={handleSave}
            disabled={saving || !selectedPage}
            className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            <Save className="w-4 h-4" />
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>

      {selectedPage ? (
        <div className="flex-1 grid grid-cols-12 gap-6">
          {/* Sidebar */}
          <div className="col-span-3 bg-white rounded-lg shadow p-4">
            <h2 className="font-semibold mb-4">Page Settings</h2>
            
            {/* Basic Settings */}
            <div className="space-y-4 mb-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Page Title
                </label>
                <input
                  type="text"
                  className="w-full rounded-md border-gray-300"
                  value={selectedPage.title}
                  onChange={(e) => setSelectedPage({
                    ...selectedPage,
                    title: e.target.value
                  })}
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  URL Slug
                </label>
                <input
                  type="text"
                  className="w-full rounded-md border-gray-300"
                  value={selectedPage.slug}
                  onChange={(e) => setSelectedPage({
                    ...selectedPage,
                    slug: e.target.value
                  })}
                />
              </div>
            </div>

            {/* SEO Settings */}
            <div className="mb-6">
              <h3 className="font-medium mb-3">SEO Settings</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Meta Title
                  </label>
                  <input
                    type="text"
                    className="w-full rounded-md border-gray-300"
                    value={selectedPage.seo.title}
                    onChange={(e) => setSelectedPage({
                      ...selectedPage,
                      seo: { ...selectedPage.seo, title: e.target.value }
                    })}
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Meta Description
                  </label>
                  <textarea
                    className="w-full rounded-md border-gray-300"
                    rows={3}
                    value={selectedPage.seo.description}
                    onChange={(e) => setSelectedPage({
                      ...selectedPage,
                      seo: { ...selectedPage.seo, description: e.target.value }
                    })}
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Keywords
                  </label>
                  <input
                    type="text"
                    className="w-full rounded-md border-gray-300"
                    value={selectedPage.seo.keywords}
                    onChange={(e) => setSelectedPage({
                      ...selectedPage,
                      seo: { ...selectedPage.seo, keywords: e.target.value }
                    })}
                  />
                </div>
              </div>
            </div>

            {/* Add Section */}
            <div>
              <h3 className="font-medium mb-3">Add Section</h3>
              <div className="grid grid-cols-2 gap-2">
                <button
                  onClick={() => handleSectionAdd('hero')}
                  className="p-2 text-sm text-center border rounded hover:bg-gray-50"
                >
                  Hero Section
                </button>
                <button
                  onClick={() => handleSectionAdd('features')}
                  className="p-2 text-sm text-center border rounded hover:bg-gray-50"
                >
                  Features Grid
                </button>
                <button
                  onClick={() => handleSectionAdd('content')}
                  className="p-2 text-sm text-center border rounded hover:bg-gray-50"
                >
                  Content Block
                </button>
                <button
                  onClick={() => handleSectionAdd('cta')}
                  className="p-2 text-sm text-center border rounded hover:bg-gray-50"
                >
                  CTA Section
                </button>
              </div>
            </div>
          </div>

          {/* Main Editor */}
          <div className="col-span-9 bg-white rounded-lg shadow">
            {editMode === 'visual' ? (
              <div className="p-4">
                {selectedPage.sections.map((section, index) => (
                  <div
                    key={section.id}
                    className="mb-4 border rounded-lg p-4"
                  >
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="font-medium capitalize">
                        {section.type} Section
                      </h3>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleSectionMove(section.id, 'up')}
                          disabled={index === 0}
                          className="p-1 hover:bg-gray-100 rounded disabled:opacity-50"
                        >
                          <ArrowUp className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleSectionMove(section.id, 'down')}
                          disabled={index === selectedPage.sections.length - 1}
                          className="p-1 hover:bg-gray-100 rounded disabled:opacity-50"
                        >
                          <ArrowDown className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleSectionDelete(section.id)}
                          className="p-1 text-red-600 hover:bg-red-50 rounded"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    </div>

                    <Editor
                      apiKey="your-tinymce-api-key"
                      init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                          'preview', 'anchor', 'searchreplace', 'visualblocks', 'code',
                          'fullscreen', 'insertdatetime', 'media', 'table', 'code',
                          'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                          'bold italic forecolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help'
                      }}
                      value={section.content.html || ''}
                      onEditorChange={(content) => {
                        const newSections = [...selectedPage.sections];
                        const sectionIndex = newSections.findIndex(s => s.id === section.id);
                        newSections[sectionIndex].content.html = content;
                        setSelectedPage({
                          ...selectedPage,
                          sections: newSections
                        });
                      }}
                    />
                  </div>
                ))}

                {selectedPage.sections.length === 0 && (
                  <div className="text-center py-12 text-gray-500">
                    <Plus className="w-8 h-8 mx-auto mb-2" />
                    <p>Add a section to get started</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="p-4">
                <textarea
                  className="w-full h-[calc(100vh-300px)] font-mono text-sm p-4 border rounded"
                  value={JSON.stringify(selectedPage, null, 2)}
                  onChange={(e) => {
                    try {
                      const parsed = JSON.parse(e.target.value);
                      setSelectedPage(parsed);
                    } catch (error) {
                      // Invalid JSON, ignore
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center text-gray-500">
          Select a page to edit
        </div>
      )}
    </div>
  );
}