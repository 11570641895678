import React from 'react';
import { useAuth } from '../context/AuthContext';

function Dashboard() {
  const { userData } = useAuth();

  return (
    <div>
      <h1 className="text-3xl font-bold mb-8">Welcome back, {userData?.name}</h1>
      {/* Add dashboard content */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-2">Your Credits</h2>
          <p className="text-3xl font-bold">{userData?.credits}</p>
        </div>
        {/* Add more dashboard widgets */}
      </div>
    </div>
  );
}

export default Dashboard;