import React, { useState } from 'react';
import { Play, Pause } from 'lucide-react';
import toast from 'react-hot-toast';

interface Voice {
  id: string;
  name: string;
  gender: 'male' | 'female';
  language: string;
  preview: string;
}

interface VoiceSelectionProps {
  onSelect: (voiceId: string) => void;
  script: string;
}

const VOICES: Voice[] = [
  { id: 'en-US-1', name: 'Sarah', gender: 'female', language: 'English (US)', preview: '' },
  { id: 'en-US-2', name: 'Michael', gender: 'male', language: 'English (US)', preview: '' },
  { id: 'en-GB-1', name: 'Emma', gender: 'female', language: 'English (UK)', preview: '' },
  { id: 'en-GB-2', name: 'James', gender: 'male', language: 'English (UK)', preview: '' },
  // Add more voices as needed
];

export default function VoiceSelection({ onSelect, script }: VoiceSelectionProps) {
  const [selectedVoice, setSelectedVoice] = useState<string>('');
  const [playingPreview, setPlayingPreview] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handlePreviewPlay = async (voiceId: string) => {
    if (playingPreview === voiceId) {
      // Stop playing
      setPlayingPreview(null);
      // Implement stop audio logic
      return;
    }

    setPlayingPreview(voiceId);
    try {
      // Implement preview generation and playback
      await voiceService.playPreview(voiceId, script.slice(0, 100));
    } catch (error) {
      toast.error('Failed to play voice preview');
    } finally {
      setPlayingPreview(null);
    }
  };

  const handleContinue = () => {
    if (!selectedVoice) {
      toast.error('Please select a voice');
      return;
    }
    onSelect(selectedVoice);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold mb-4">Select a Voice</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {VOICES.map((voice) => (
          <div
            key={voice.id}
            onClick={() => setSelectedVoice(voice.id)}
            className={`p-4 rounded-lg border-2 cursor-pointer ${
              selectedVoice === voice.id
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-200'
            }`}
          >
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="font-medium">{voice.name}</h3>
                <p className="text-sm text-gray-500">{voice.language}</p>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handlePreviewPlay(voice.id);
                }}
                className="p-2 hover:bg-indigo-100 rounded-full"
              >
                {playingPreview === voice.id ? (
                  <Pause className="w-5 h-5" />
                ) : (
                  <Play className="w-5 h-5" />
                )}
              </button>
            </div>
            <div className="text-sm text-gray-600">
              <span className={`px-2 py-1 rounded-full text-xs ${
                voice.gender === 'female' ? 'bg-pink-100 text-pink-800' : 'bg-blue-100 text-blue-800'
              }`}>
                {voice.gender}
              </span>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={handleContinue}
        className="w-full py-2 px-4 bg-green-600 text-white rounded-md hover:bg-green-700"
      >
        Continue to Aspect Ratio
      </button>
    </div>
  );
}