import React from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  Settings, 
  FileText, 
  CreditCard,
  Bell,
  Shield,
  Database,
  Palette,
  LogOut
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import AdminHeader from '../components/admin/AdminHeader';

function AdminLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  
  const navItems = [
    { path: '/admin', icon: <LayoutDashboard className="w-5 h-5" />, label: 'Dashboard', exact: true },
    { path: '/admin/users', icon: <Users className="w-5 h-5" />, label: 'Users' },
    { path: '/admin/content', icon: <FileText className="w-5 h-5" />, label: 'Content' },
    { path: '/admin/payments', icon: <CreditCard className="w-5 h-5" />, label: 'Payments' },
    { path: '/admin/api-manager', icon: <Database className="w-5 h-5" />, label: 'API Manager' },
    { path: '/admin/notifications', icon: <Bell className="w-5 h-5" />, label: 'Notifications' },
    { path: '/admin/security', icon: <Shield className="w-5 h-5" />, label: 'Security' },
    { path: '/admin/theme', icon: <Palette className="w-5 h-5" />, label: 'Theme' },
    { path: '/admin/settings', icon: <Settings className="w-5 h-5" />, label: 'Settings' }
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/admin/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const isActive = (path: string, exact = false) => {
    if (exact) {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark">
      <AdminHeader />
      
      <div className="flex min-h-[calc(100vh-4rem)] pt-16"> {/* Added pt-16 to account for fixed header */}
        {/* Sidebar */}
        <aside className="fixed inset-y-16 left-0 w-64 bg-indigo-900 dark:bg-dark-paper text-white overflow-y-auto">
          <nav className="p-4">
            <ul className="space-y-2">
              {navItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={`flex items-center px-4 py-2 rounded-md transition-colors ${
                      isActive(item.path, item.exact)
                        ? 'bg-indigo-800/50 dark:bg-dark-hover'
                        : 'hover:bg-indigo-800/30 dark:hover:bg-dark-hover'
                    }`}
                  >
                    {item.icon}
                    <span className="ml-3">{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>

            <div className="mt-8 pt-8 border-t border-indigo-800/30">
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-4 py-2 text-white hover:bg-indigo-800/30 dark:hover:bg-dark-hover rounded-md"
              >
                <LogOut className="w-5 h-5 mr-3" />
                Logout
              </button>
            </div>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="ml-64 flex-1 p-8 bg-gray-50 dark:bg-dark">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default AdminLayout;