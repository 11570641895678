import React from 'react';
import { Play, Download, Share2 } from 'lucide-react';

interface VideoPreviewProps {
  config: {
    script: string;
    scriptSource: 'ai' | 'url' | 'manual';
    voiceId: string;
    aspectRatio: '9:16' | '16:9' | '1:1';
    imageStyle: string;
  };
  onGenerate: () => Promise<void>;
  generating: boolean;
}

export default function VideoPreview({ config, onGenerate, generating }: VideoPreviewProps) {
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold mb-4">Generate Video</h2>

      <div className="bg-gray-50 p-6 rounded-lg">
        <h3 className="font-medium mb-4">Video Configuration Summary</h3>
        <dl className="grid grid-cols-2 gap-4">
          <div>
            <dt className="text-sm text-gray-500">Script Source</dt>
            <dd className="mt-1 font-medium">{config.scriptSource}</dd>
          </div>
          <div>
            <dt className="text-sm text-gray-500">Voice</dt>
            <dd className="mt-1 font-medium">{config.voiceId}</dd>
          </div>
          <div>
            <dt className="text-sm text-gray-500">Aspect Ratio</dt>
            <dd className="mt-1 font-medium">{config.aspectRatio}</dd>
          </div>
          <div>
            <dt className="text-sm text-gray-500">Visual Style</dt>
            <dd className="mt-1 font-medium">{config.imageStyle}</dd>
          </div>
        </dl>
      </div>

      <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4">
        <p className="text-sm text-yellow-800">
          Generating this video will use 10 credits from your account. The process may take a few minutes.
        </p>
      </div>

      <button
        onClick={onGenerate}
        disabled={generating}
        className="w-full py-3 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50 flex items-center justify-center"
      >
        {generating ? (
          <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Generating Video...
          </>
        ) : (
          'Generate Video'
        )}
      </button>
    </div>
  );
}