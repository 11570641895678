import React from 'react';
import { useAuth } from '../context/AuthContext';

function Settings() {
  const { userData } = useAuth();

  return (
    <div>
      <h1 className="text-3xl font-bold mb-8">Account Settings</h1>
      {/* Add settings content */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Profile Information</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={userData?.name}
            />
          </div>
          {/* Add more settings fields */}
        </div>
      </div>
    </div>
  );
}

export default Settings;