import React, { createContext, useContext, useState, useEffect } from 'react';

interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
  updateThemeColors: (colors: any) => void;
}

const ThemeContext = createContext<ThemeContextType>({
  isDark: false,
  toggleTheme: () => {},
  updateThemeColors: () => {}
});

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [isDark, setIsDark] = useState(() => {
    if (typeof window === 'undefined') return false;
    
    const saved = localStorage.getItem('theme');
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    return saved === 'dark' || (!saved && prefersDark);
  });

  useEffect(() => {
    const root = window.document.documentElement;
    
    if (isDark) {
      root.classList.add('dark');
      localStorage.setItem('theme', 'dark');
      
      // Apply saved dark mode colors if they exist
      const savedTheme = localStorage.getItem('admin_theme');
      if (savedTheme) {
        const { dark } = JSON.parse(savedTheme);
        Object.entries(dark).forEach(([key, value]) => {
          root.style.setProperty(`--${key}`, value as string);
        });
      }
    } else {
      root.classList.remove('dark');
      localStorage.setItem('theme', 'light');
      
      // Apply saved light mode colors if they exist
      const savedTheme = localStorage.getItem('admin_theme');
      if (savedTheme) {
        const { light } = JSON.parse(savedTheme);
        Object.entries(light).forEach(([key, value]) => {
          root.style.setProperty(`--${key}`, value as string);
        });
      }
    }
  }, [isDark]);

  const toggleTheme = () => {
    setIsDark(prev => !prev);
  };

  const updateThemeColors = (colors: any) => {
    const root = window.document.documentElement;
    const mode = isDark ? 'dark' : 'light';
    
    Object.entries(colors[mode]).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value as string);
    });
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme, updateThemeColors }}>
      {children}
    </ThemeContext.Provider>
  );
}