import React from 'react';
import { Users, Video, DollarSign, Activity } from 'lucide-react';
import AdminMetricCard from '../../components/admin/AdminMetricCard';
import AdminChart from '../../components/admin/AdminChart';

function AdminDashboard() {
  const metrics = [
    {
      title: 'Total Users',
      value: '1,234',
      change: 12.5,
      icon: <Users className="w-6 h-6 text-indigo-600" />
    },
    {
      title: 'Videos Created',
      value: '8,567',
      change: 23.1,
      icon: <Video className="w-6 h-6 text-green-600" />
    },
    {
      title: 'Revenue',
      value: '$12,435',
      change: -5.2,
      icon: <DollarSign className="w-6 h-6 text-blue-600" />
    },
    {
      title: 'Active Subscriptions',
      value: '892',
      change: 8.1,
      icon: <Activity className="w-6 h-6 text-purple-600" />
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Admin Dashboard</h1>
        <div className="flex gap-2">
          <select className="rounded-md border-gray-300 text-sm">
            <option>Last 7 days</option>
            <option>Last 30 days</option>
            <option>Last 90 days</option>
          </select>
          <button className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm hover:bg-indigo-700">
            Download Report
          </button>
        </div>
      </div>

      {/* Metrics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {metrics.map((metric, index) => (
          <AdminMetricCard key={index} {...metric} />
        ))}
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-dark-paper p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Revenue Overview</h2>
          <AdminChart type="line" />
        </div>
        <div className="bg-white dark:bg-dark-paper p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">User Growth</h2>
          <AdminChart type="bar" />
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;