import React from 'react';
import { Shield, Key, Lock, Users } from 'lucide-react';

export default function SecuritySettings() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Security Settings</h1>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {/* Authentication Settings */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center gap-3 mb-4">
            <Key className="w-5 h-5 text-indigo-600" />
            <h2 className="text-lg font-semibold">Authentication Settings</h2>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Two-Factor Authentication</p>
                <p className="text-sm text-gray-500">Add an extra layer of security to your account</p>
              </div>
              <button className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">
                Enable
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Session Management</p>
                <p className="text-sm text-gray-500">Control active sessions and devices</p>
              </div>
              <button className="px-4 py-2 border text-gray-700 rounded-md hover:bg-gray-50">
                Manage
              </button>
            </div>
          </div>
        </div>

        {/* Access Control */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center gap-3 mb-4">
            <Users className="w-5 h-5 text-indigo-600" />
            <h2 className="text-lg font-semibold">Access Control</h2>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">IP Whitelisting</p>
                <p className="text-sm text-gray-500">Restrict access to specific IP addresses</p>
              </div>
              <button className="px-4 py-2 border text-gray-700 rounded-md hover:bg-gray-50">
                Configure
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Role Management</p>
                <p className="text-sm text-gray-500">Manage user roles and permissions</p>
              </div>
              <button className="px-4 py-2 border text-gray-700 rounded-md hover:bg-gray-50">
                Manage Roles
              </button>
            </div>
          </div>
        </div>

        {/* Security Logs */}
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center gap-3 mb-4">
            <Shield className="w-5 h-5 text-indigo-600" />
            <h2 className="text-lg font-semibold">Security Logs</h2>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Activity Logs</p>
                <p className="text-sm text-gray-500">View security-related activity</p>
              </div>
              <button className="px-4 py-2 border text-gray-700 rounded-md hover:bg-gray-50">
                View Logs
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Security Alerts</p>
                <p className="text-sm text-gray-500">Configure security alert notifications</p>
              </div>
              <button className="px-4 py-2 border text-gray-700 rounded-md hover:bg-gray-50">
                Configure
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}