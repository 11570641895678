import React, { useState } from 'react';
import { Smartphone, Monitor, Square } from 'lucide-react';

interface AspectRatioSelectorProps {
  onSelect: (ratio: '9:16' | '16:9' | '1:1') => void;
}

interface AspectRatioOption {
  ratio: '9:16' | '16:9' | '1:1';
  label: string;
  description: string;
  icon: React.ReactNode;
}

const OPTIONS: AspectRatioOption[] = [
  {
    ratio: '9:16',
    label: 'Vertical (9:16)',
    description: 'Perfect for TikTok, Instagram Reels, and YouTube Shorts',
    icon: <Smartphone className="w-6 h-6" />
  },
  {
    ratio: '16:9',
    label: 'Horizontal (16:9)',
    description: 'Standard format for YouTube and traditional video platforms',
    icon: <Monitor className="w-6 h-6" />
  },
  {
    ratio: '1:1',
    label: 'Square (1:1)',
    description: 'Ideal for Instagram feed posts and LinkedIn',
    icon: <Square className="w-6 h-6" />
  }
];

export default function AspectRatioSelector({ onSelect }: AspectRatioSelectorProps) {
  const [selectedRatio, setSelectedRatio] = useState<'9:16' | '16:9' | '1:1'>('9:16');

  const handleContinue = () => {
    onSelect(selectedRatio);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold mb-4">Choose Video Aspect Ratio</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {OPTIONS.map((option) => (
          <div
            key={option.ratio}
            onClick={() => setSelectedRatio(option.ratio)}
            className={`p-6 rounded-lg border-2 cursor-pointer ${
              selectedRatio === option.ratio
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-200'
            }`}
          >
            <div className="flex flex-col items-center text-center">
              <div className="mb-4">{option.icon}</div>
              <h3 className="font-medium mb-2">{option.label}</h3>
              <p className="text-sm text-gray-500">{option.description}</p>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={handleContinue}
        className="w-full py-2 px-4 bg-green-600 text-white rounded-md hover:bg-green-700"
      >
        Continue to Visual Style
      </button>
    </div>
  );
}