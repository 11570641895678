import React from 'react';
import { useAuth } from '../context/AuthContext';
import CreditPurchase from '../components/CreditPurchase';

const Billing: React.FC = () => {
  const { userData } = useAuth();

  const handlePurchase = (amount: number) => {
    // Handle credit purchase
    console.log('Purchased credits:', amount);
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-8">Billing & Credits</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <CreditPurchase
          currentCredits={userData?.credits || 0}
          onPurchase={handlePurchase}
        />
        {/* Add billing history and other components */}
      </div>
    </div>
  );
};

export default Billing;