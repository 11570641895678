import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Settings, 
  CreditCard, 
  Video,
  LogOut
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';

function UserLayout() {
  const location = useLocation();
  const { logout } = useAuth();

  const navItems = [
    { path: '/dashboard', icon: <LayoutDashboard className="w-5 h-5" />, label: 'Dashboard' },
    { path: '/dashboard/create', icon: <Video className="w-5 h-5" />, label: 'Create Video' },
    { path: '/dashboard/billing', icon: <CreditCard className="w-5 h-5" />, label: 'Billing' },
    { path: '/dashboard/settings', icon: <Settings className="w-5 h-5" />, label: 'Settings' }
  ];

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-50 dark:bg-dark">
      {/* Sidebar */}
      <aside className="fixed inset-y-0 left-0 w-64 bg-indigo-900 dark:bg-dark-paper text-white">
        <div className="p-4">
          <Link to="/" className="text-2xl font-bold">ShortDude</Link>
        </div>

        <nav className="mt-8">
          <ul className="space-y-2">
            {navItems.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`flex items-center px-6 py-3 text-sm ${
                    location.pathname === item.path
                      ? 'bg-indigo-800/50 dark:bg-dark-hover'
                      : 'hover:bg-indigo-800/30 dark:hover:bg-dark-hover'
                  }`}
                >
                  {item.icon}
                  <span className="ml-3">{item.label}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="absolute bottom-0 w-64 p-4">
          <button
            onClick={handleLogout}
            className="flex items-center px-6 py-3 text-sm text-white hover:bg-indigo-800/30 dark:hover:bg-dark-hover w-full"
          >
            <LogOut className="w-5 h-5" />
            <span className="ml-3">Logout</span>
          </button>
        </div>
      </aside>

      {/* Main Content */}
      <main className="ml-64 flex-1 bg-gray-50 dark:bg-dark">
        <div className="container mx-auto px-6 py-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default UserLayout;