import React, { useState } from 'react';
import { Wand2, Link, PenLine } from 'lucide-react';
import toast from 'react-hot-toast';

interface ScriptCreationProps {
  onComplete: (script: string, source: 'ai' | 'url' | 'manual') => void;
}

export default function ScriptCreation({ onComplete }: ScriptCreationProps) {
  const [scriptSource, setScriptSource] = useState<'ai' | 'url' | 'manual'>('manual');
  const [script, setScript] = useState('');
  const [url, setUrl] = useState('');
  const [prompt, setPrompt] = useState('');
  const [generating, setGenerating] = useState(false);

  const handleAIGenerate = async () => {
    if (!prompt.trim()) {
      toast.error('Please enter a prompt for the AI');
      return;
    }

    setGenerating(true);
    try {
      // Implement AI script generation here
      const generatedScript = await aiService.generateScript(prompt);
      setScript(generatedScript);
      toast.success('Script generated successfully!');
    } catch (error) {
      toast.error('Failed to generate script');
    } finally {
      setGenerating(false);
    }
  };

  const handleUrlImport = async () => {
    if (!url.trim()) {
      toast.error('Please enter a valid URL');
      return;
    }

    try {
      // Implement URL content extraction here
      const extractedContent = await contentService.extractFromUrl(url);
      setScript(extractedContent);
      toast.success('Content imported successfully!');
    } catch (error) {
      toast.error('Failed to import content from URL');
    }
  };

  const handleSubmit = () => {
    if (!script.trim()) {
      toast.error('Please enter or generate a script');
      return;
    }
    onComplete(script, scriptSource);
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-xl font-semibold mb-4">Create Your Script</h2>
        <div className="grid grid-cols-3 gap-4 mb-6">
          <button
            onClick={() => setScriptSource('ai')}
            className={`p-4 rounded-lg border-2 flex flex-col items-center ${
              scriptSource === 'ai'
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-200'
            }`}
          >
            <Wand2 className="w-6 h-6 mb-2" />
            <span>AI Generate</span>
          </button>
          <button
            onClick={() => setScriptSource('url')}
            className={`p-4 rounded-lg border-2 flex flex-col items-center ${
              scriptSource === 'url'
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-200'
            }`}
          >
            <Link className="w-6 h-6 mb-2" />
            <span>Import from URL</span>
          </button>
          <button
            onClick={() => setScriptSource('manual')}
            className={`p-4 rounded-lg border-2 flex flex-col items-center ${
              scriptSource === 'manual'
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-200'
            }`}
          >
            <PenLine className="w-6 h-6 mb-2" />
            <span>Write Manually</span>
          </button>
        </div>
      </div>

      {scriptSource === 'ai' && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              What kind of video would you like to create?
            </label>
            <textarea
              rows={3}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="E.g., Create a 60-second video about the benefits of meditation..."
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
          </div>
          <button
            onClick={handleAIGenerate}
            disabled={generating}
            className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            {generating ? 'Generating...' : 'Generate Script'}
          </button>
        </div>
      )}

      {scriptSource === 'url' && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Enter URL to import content
            </label>
            <div className="flex gap-2">
              <input
                type="url"
                className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="https://..."
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <button
                onClick={handleUrlImport}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
              >
                Import
              </button>
            </div>
          </div>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Script
        </label>
        <textarea
          rows={8}
          className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Enter your video script here..."
          value={script}
          onChange={(e) => setScript(e.target.value)}
        />
      </div>

      <button
        onClick={handleSubmit}
        className="w-full py-2 px-4 bg-green-600 text-white rounded-md hover:bg-green-700"
      >
        Continue to Voice Selection
      </button>
    </div>
  );
}