import React from 'react';
import { Link } from 'react-router-dom';
import { useFooter } from '../context/FooterContext';

const Footer: React.FC = () => {
  const { footerLinks } = useFooter();

  const getColumnLinks = (column: string) => {
    return footerLinks
      .filter(link => link.column === column)
      .sort((a, b) => a.order - b.order);
  };

  return (
    <footer className="bg-white dark:bg-dark-paper border-t border-gray-200 dark:border-dark-border">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-dark-text-primary mb-4">Company</h3>
            <ul className="space-y-2">
              {getColumnLinks('company').map(link => (
                <li key={link.id}>
                  <Link
                    to={link.url}
                    className="text-gray-600 dark:text-dark-text-secondary hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-dark-text-primary mb-4">Legal</h3>
            <ul className="space-y-2">
              {getColumnLinks('legal').map(link => (
                <li key={link.id}>
                  <Link
                    to={link.url}
                    className="text-gray-600 dark:text-dark-text-secondary hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-dark-text-primary mb-4">Resources</h3>
            <ul className="space-y-2">
              {getColumnLinks('resources').map(link => (
                <li key={link.id}>
                  <Link
                    to={link.url}
                    className="text-gray-600 dark:text-dark-text-secondary hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-900 dark:text-dark-text-primary mb-4">Social</h3>
            <ul className="space-y-2">
              {getColumnLinks('social').map(link => (
                <li key={link.id}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 dark:text-dark-text-secondary hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors"
                  >
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200 dark:border-dark-border text-center text-gray-500 dark:text-dark-text-muted">
          <p>© {new Date().getFullYear()} ShortDude. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;