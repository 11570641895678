import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { Bell, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';
import ThemeToggle from '../ThemeToggle';

function AdminHeader() {
  const { userData } = useAuth();

  return (
    <header className="h-16 bg-white dark:bg-dark-paper border-b border-gray-200 dark:border-dark-border fixed top-0 left-0 right-0 z-10">
      <div className="h-full px-4 flex items-center justify-between">
        <Link to="/admin" className="flex items-center">
          <h1 className="text-xl font-bold text-indigo-600 dark:text-indigo-400">
            ShortDude Admin
          </h1>
        </Link>

        <div className="flex items-center space-x-4">
          <ThemeToggle />
          <button className="p-2 hover:bg-gray-100 dark:hover:bg-dark-hover rounded-full">
            <Bell className="w-5 h-5 text-gray-600 dark:text-gray-300" />
          </button>
          <Link 
            to="/admin/settings"
            className="p-2 hover:bg-gray-100 dark:hover:bg-dark-hover rounded-full"
          >
            <Settings className="w-5 h-5 text-gray-600 dark:text-gray-300" />
          </Link>
          <div className="flex items-center">
            <div className="w-8 h-8 bg-indigo-100 dark:bg-indigo-900 rounded-full flex items-center justify-center">
              <span className="text-sm font-medium text-indigo-600 dark:text-indigo-400">
                {userData?.name?.charAt(0) || 'A'}
              </span>
            </div>
            <span className="ml-2 text-sm font-medium text-gray-700 dark:text-gray-200">
              {userData?.name || 'Admin'}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AdminHeader;