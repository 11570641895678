import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// Layouts
import UserLayout from './layouts/UserLayout';
import AdminLayout from './layouts/AdminLayout';

// Public Pages
import Home from './pages/Home';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

// Auth Pages
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import AdminLogin from './pages/admin/Login';

// User Pages
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Billing from './pages/Billing';
import CreateVideo from './pages/CreateVideo';

// Admin Pages
import AdminDashboard from './pages/admin/Dashboard';
import ApiManager from './pages/admin/ApiManager';
import UserManagement from './pages/admin/UserManagement';
import AdminSettings from './pages/admin/Settings';
import FooterManager from './pages/admin/FooterManager';
import HeaderManager from './pages/admin/HeaderManager';
import PageEditor from './pages/admin/PageEditor';
import ThemeManager from './pages/admin/ThemeManager';
import ContentManager from './pages/admin/ContentManager';
import PaymentManager from './pages/admin/PaymentManager';
import NotificationManager from './pages/admin/NotificationManager';
import SecuritySettings from './pages/admin/SecuritySettings';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import RequireAuth from './components/auth/RequireAuth';
import RequireAdmin from './components/auth/RequireAdmin';

// Context Providers
import { AuthProvider } from './context/AuthContext';
import { CryptoProvider } from './context/CryptoContext';
import { PaymentProvider } from './context/PaymentContext';
import { FooterProvider } from './context/FooterContext';
import { HeaderProvider } from './context/HeaderContext';
import { ThemeProvider } from './context/ThemeContext';

export default function App() {
  return (
    <AuthProvider>
      <PaymentProvider>
        <CryptoProvider>
          <HeaderProvider>
            <FooterProvider>
              <ThemeProvider>
                <Router>
                  <div className="min-h-screen bg-gray-50 dark:bg-dark text-gray-900 dark:text-gray-100">
                    <Toaster position="top-right" />
                    <Header />
                    <main className="flex-grow bg-gray-50 dark:bg-dark">
                      <Routes>
                        {/* Public routes */}
                        <Route path="/" element={<Home />} />
                        <Route path="/features" element={<Features />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element={<TermsOfService />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/admin/login" element={<AdminLogin />} />

                        {/* Protected user routes */}
                        <Route element={<RequireAuth />}>
                          <Route path="/dashboard" element={<UserLayout />}>
                            <Route index element={<Dashboard />} />
                            <Route path="create" element={<CreateVideo />} />
                            <Route path="billing" element={<Billing />} />
                            <Route path="settings" element={<Settings />} />
                          </Route>
                        </Route>

                        {/* Protected admin routes */}
                        <Route element={<RequireAdmin />}>
                          <Route path="/admin" element={<AdminLayout />}>
                            <Route index element={<AdminDashboard />} />
                            <Route path="users" element={<UserManagement />} />
                            <Route path="content" element={<ContentManager />} />
                            <Route path="payments" element={<PaymentManager />} />
                            <Route path="api-manager" element={<ApiManager />} />
                            <Route path="notifications" element={<NotificationManager />} />
                            <Route path="security" element={<SecuritySettings />} />
                            <Route path="theme" element={<ThemeManager />} />
                            <Route path="settings" element={<AdminSettings />} />
                            <Route path="footer" element={<FooterManager />} />
                            <Route path="header" element={<HeaderManager />} />
                            <Route path="pages" element={<PageEditor />} />
                          </Route>
                        </Route>

                        {/* Catch all */}
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
                    </main>
                    <Footer />
                  </div>
                </Router>
              </ThemeProvider>
            </FooterProvider>
          </HeaderProvider>
        </CryptoProvider>
      </PaymentProvider>
    </AuthProvider>
  );
}