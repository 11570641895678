import React, { useState } from 'react';
import { Save, Plus, Trash2, MoveVertical, Upload } from 'lucide-react';
import toast from 'react-hot-toast';
import { useHeader } from '../../context/HeaderContext';
import { SketchPicker } from 'react-color';

interface NavLink {
  id: string;
  text: string;
  url: string;
  order: number;
}

function HeaderManager() {
  const { headerSettings, updateHeaderSettings } = useHeader();
  const [settings, setSettings] = useState(headerSettings);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [newLink, setNewLink] = useState<Partial<NavLink>>({ text: '', url: '' });
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState(settings.logoUrl);

  const handleSave = async () => {
    try {
      await updateHeaderSettings(settings);
      toast.success('Header settings updated successfully');
    } catch (error) {
      toast.error('Failed to update header settings');
    }
  };

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast.error('Please upload an image file');
      return;
    }

    setLogoFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const preview = e.target?.result as string;
      setLogoPreview(preview);
      setSettings({ ...settings, logoUrl: preview });
    };
    reader.readAsDataURL(file);
  };

  const handleAddLink = () => {
    if (!newLink.text || !newLink.url) {
      toast.error('Please fill in all fields');
      return;
    }

    const maxOrder = Math.max(...settings.navLinks.map(link => link.order), 0);
    const newNavLink: NavLink = {
      id: crypto.randomUUID(),
      text: newLink.text,
      url: newLink.url,
      order: maxOrder + 1
    };

    setSettings({
      ...settings,
      navLinks: [...settings.navLinks, newNavLink]
    });
    setNewLink({ text: '', url: '' });
    toast.success('Navigation link added successfully');
  };

  const handleDeleteLink = (id: string) => {
    setSettings({
      ...settings,
      navLinks: settings.navLinks.filter(link => link.id !== id)
    });
  };

  const handleMoveLink = (id: string, direction: 'up' | 'down') => {
    const index = settings.navLinks.findIndex(link => link.id === id);
    if (index === -1) return;

    const newLinks = [...settings.navLinks];
    const currentLink = newLinks[index];
    const adjacentLink = newLinks[direction === 'up' ? index - 1 : index + 1];

    if (!adjacentLink) return;

    const tempOrder = currentLink.order;
    currentLink.order = adjacentLink.order;
    adjacentLink.order = tempOrder;

    newLinks.sort((a, b) => a.order - b.order);
    setSettings({ ...settings, navLinks: newLinks });
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Header Management</h1>
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
        >
          <Save className="w-4 h-4 mr-2" />
          Save Changes
        </button>
      </div>

      {/* Logo Settings */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Logo Settings</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Logo Type
            </label>
            <select
              className="w-full rounded-md border-gray-300"
              value={settings.logoType}
              onChange={(e) => setSettings({ ...settings, logoType: e.target.value as 'text' | 'image' })}
            >
              <option value="text">Text</option>
              <option value="image">Image</option>
            </select>
          </div>

          {settings.logoType === 'text' ? (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Logo Text
                </label>
                <input
                  type="text"
                  className="w-full rounded-md border-gray-300"
                  value={settings.logoText}
                  onChange={(e) => setSettings({ ...settings, logoText: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Font Family
                </label>
                <select
                  className="w-full rounded-md border-gray-300"
                  value={settings.logoFont}
                  onChange={(e) => setSettings({ ...settings, logoFont: e.target.value })}
                >
                  <option value="sans">Sans-serif</option>
                  <option value="serif">Serif</option>
                  <option value="mono">Monospace</option>
                </select>
              </div>
            </>
          ) : (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Logo Image
              </label>
              <div className="space-y-4">
                {logoPreview && (
                  <div className="w-48 h-16 relative">
                    <img
                      src={logoPreview}
                      alt="Logo preview"
                      className="max-w-full max-h-full object-contain"
                    />
                  </div>
                )}
                <label className="flex items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-indigo-500 focus:outline-none">
                  <div className="flex flex-col items-center space-y-2">
                    <Upload className="w-8 h-8 text-gray-400" />
                    <span className="text-sm text-gray-500">
                      Click to upload logo image
                    </span>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleLogoUpload}
                  />
                </label>
                <p className="text-sm text-gray-500">
                  Recommended size: 200x50px, max file size: 2MB
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Color Settings */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Color Settings</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Primary Color
            </label>
            <div className="flex items-center space-x-2">
              <div
                className="w-10 h-10 rounded border cursor-pointer"
                style={{ backgroundColor: settings.primaryColor }}
                onClick={() => setShowColorPicker(!showColorPicker)}
              />
              <input
                type="text"
                className="rounded-md border-gray-300"
                value={settings.primaryColor}
                onChange={(e) => setSettings({ ...settings, primaryColor: e.target.value })}
              />
            </div>
            {showColorPicker && (
              <div className="absolute mt-2 z-10">
                <div
                  className="fixed inset-0"
                  onClick={() => setShowColorPicker(false)}
                />
                <SketchPicker
                  color={settings.primaryColor}
                  onChange={(color) => setSettings({ ...settings, primaryColor: color.hex })}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Navigation Links */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Navigation Links</h2>
        
        {/* Add New Link */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <input
            type="text"
            placeholder="Link Text"
            className="rounded-md border-gray-300"
            value={newLink.text}
            onChange={(e) => setNewLink({ ...newLink, text: e.target.value })}
          />
          <input
            type="text"
            placeholder="URL"
            className="rounded-md border-gray-300"
            value={newLink.url}
            onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
          />
          <button
            onClick={handleAddLink}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center justify-center"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Link
          </button>
        </div>

        {/* Links List */}
        <ul className="space-y-2">
          {settings.navLinks
            .sort((a, b) => a.order - b.order)
            .map(link => (
              <li
                key={link.id}
                className="flex items-center justify-between bg-gray-50 p-3 rounded"
              >
                <span className="font-medium">{link.text}</span>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleMoveLink(link.id, 'up')}
                    className="p-1 hover:bg-gray-200 rounded"
                    title="Move up"
                  >
                    <MoveVertical className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleDeleteLink(link.id)}
                    className="p-1 text-red-600 hover:bg-red-50 rounded"
                    title="Delete"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default HeaderManager;