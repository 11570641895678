import React, { useState } from 'react';
import { Save } from 'lucide-react';
import toast from 'react-hot-toast';
import { usePayment } from '../../context/PaymentContext';

interface SettingsSection {
  title: string;
  description: string;
  fields: {
    id: string;
    label: string;
    type: string;
    value: string | boolean;
    description?: string;
    options?: string[];
  }[];
}

function Settings() {
  const { settings, updateSettings } = usePayment();
  const [sections, setSections] = useState<SettingsSection[]>([
    {
      title: 'General Settings',
      description: 'Configure general application settings',
      fields: [
        {
          id: 'siteName',
          label: 'Site Name',
          type: 'text',
          value: 'ShortDude',
          description: 'The name of your site displayed in the browser tab and emails'
        },
        {
          id: 'maintenanceMode',
          label: 'Maintenance Mode',
          type: 'toggle',
          value: false,
          description: 'Enable to show maintenance page to all users'
        }
      ]
    },
    {
      title: 'Payment Settings',
      description: 'Configure payment and subscription settings',
      fields: [
        {
          id: 'cryptoPayments',
          label: 'Cryptocurrency Payments',
          type: 'toggle',
          value: settings.cryptoPaymentsEnabled,
          description: 'Enable or disable cryptocurrency payment options'
        },
        {
          id: 'subscriptionPrice',
          label: 'Monthly Subscription Price ($)',
          type: 'number',
          value: '47',
          description: 'Base price for monthly subscription'
        },
        {
          id: 'cryptoDiscount',
          label: 'Crypto Payment Discount (%)',
          type: 'number',
          value: '15',
          description: 'Discount percentage for cryptocurrency payments'
        }
      ]
    }
    // ... other sections
  ]);

  const handleSaveSettings = async () => {
    try {
      // Find crypto payments field and update the context
      const paymentSection = sections.find(s => s.title === 'Payment Settings');
      const cryptoField = paymentSection?.fields.find(f => f.id === 'cryptoPayments');
      
      if (cryptoField) {
        await updateSettings({
          cryptoPaymentsEnabled: cryptoField.value as boolean
        });
      }

      toast.success('Settings saved successfully');
    } catch (error) {
      toast.error('Failed to save settings');
    }
  };

  const handleFieldChange = (sectionIndex: number, fieldId: string, value: string | boolean) => {
    const newSections = [...sections];
    const fieldIndex = newSections[sectionIndex].fields.findIndex(f => f.id === fieldId);
    newSections[sectionIndex].fields[fieldIndex].value = value;
    setSections(newSections);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Settings</h1>
        <button
          onClick={handleSaveSettings}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
        >
          <Save className="w-4 h-4 mr-2" />
          Save Changes
        </button>
      </div>

      <div className="space-y-8">
        {sections.map((section, sectionIndex) => (
          <div key={section.title} className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-2">{section.title}</h2>
            <p className="text-gray-600 mb-6">{section.description}</p>

            <div className="space-y-6">
              {section.fields.map((field) => (
                <div key={field.id}>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {field.label}
                  </label>
                  {field.type === 'toggle' ? (
                    <div className="flex items-center">
                      <button
                        onClick={() => handleFieldChange(sectionIndex, field.id, !field.value)}
                        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                          field.value ? 'bg-indigo-600' : 'bg-gray-200'
                        }`}
                      >
                        <span
                          className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                            field.value ? 'translate-x-6' : 'translate-x-1'
                          }`}
                        />
                      </button>
                      <span className="ml-3 text-sm text-gray-500">{field.description}</span>
                    </div>
                  ) : (
                    <>
                      <input
                        type={field.type}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        value={field.value as string}
                        onChange={(e) => handleFieldChange(sectionIndex, field.id, e.target.value)}
                      />
                      {field.description && (
                        <p className="mt-1 text-sm text-gray-500">{field.description}</p>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Settings;