import React, { createContext, useContext, useEffect, useState } from 'react';
import { CryptoPriceService } from '../services/crypto/PriceService';

interface CryptoPrices {
  bitcoin: number;
  ethereum: number;
  solana: number;
}

interface CryptoContextType {
  prices: CryptoPrices;
  loading: boolean;
  error: string | null;
  refreshPrices: () => Promise<void>;
}

const CryptoContext = createContext<CryptoContextType>({
  prices: { bitcoin: 0, ethereum: 0, solana: 0 },
  loading: true,
  error: null,
  refreshPrices: async () => {}
});

export function useCrypto() {
  return useContext(CryptoContext);
}

export function CryptoProvider({ children }: { children: React.ReactNode }) {
  const [prices, setPrices] = useState<CryptoPrices>({
    bitcoin: 0,
    ethereum: 0,
    solana: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const priceService = CryptoPriceService.getInstance();

  const fetchPrices = async () => {
    try {
      const [btc, eth, sol] = await Promise.all([
        priceService.getPrice('bitcoin'),
        priceService.getPrice('ethereum'),
        priceService.getPrice('solana')
      ]);

      setPrices({
        bitcoin: btc,
        ethereum: eth,
        solana: sol
      });
      setError(null);
    } catch (err) {
      setError('Failed to fetch crypto prices');
      console.error('Error fetching crypto prices:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
    const interval = setInterval(fetchPrices, 30 * 60 * 1000); // 30 minutes
    return () => clearInterval(interval);
  }, []);

  const value = {
    prices,
    loading,
    error,
    refreshPrices: fetchPrices
  };

  return (
    <CryptoContext.Provider value={value}>
      {children}
    </CryptoContext.Provider>
  );
}