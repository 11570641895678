import React, { useState } from 'react';
import { Save, ExternalLink, Check, AlertCircle } from 'lucide-react';
import toast from 'react-hot-toast';

interface ApiKey {
  id: string;
  name: string;
  key: string;
  status: 'connected' | 'disconnected' | 'error';
  lastChecked: string;
  description: string;
  docsUrl: string;
  setupGuide: string;
  required: boolean;
}

function ApiManager() {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([
    {
      id: 'openai',
      name: 'OpenAI API',
      key: '',
      status: 'disconnected',
      lastChecked: new Date().toISOString(),
      description: 'Required for AI script generation and content creation.',
      docsUrl: 'https://platform.openai.com/docs',
      setupGuide: `1. Go to OpenAI website (https://platform.openai.com)
2. Create an account or sign in
3. Navigate to API section
4. Generate new API key
5. Copy and paste the key here
6. Test connection before saving`,
      required: true
    },
    {
      id: 'firebase',
      name: 'Firebase Configuration',
      key: '',
      status: 'disconnected',
      lastChecked: new Date().toISOString(),
      description: 'Required for storage and hosting of media files.',
      docsUrl: 'https://firebase.google.com/docs',
      setupGuide: `1. Go to Firebase Console (https://console.firebase.google.com)
2. Create a new project or select existing
3. Enable Storage service
4. Go to Project Settings
5. Find the configuration object
6. Copy and paste the entire config here`,
      required: true
    },
    {
      id: 'coinmarketcap',
      name: 'CoinMarketCap API',
      key: '',
      status: 'disconnected',
      lastChecked: new Date().toISOString(),
      description: 'Used for real-time cryptocurrency price updates.',
      docsUrl: 'https://coinmarketcap.com/api/documentation/v1/',
      setupGuide: `1. Visit CoinMarketCap (https://pro.coinmarketcap.com)
2. Sign up for an account
3. Navigate to API Keys section
4. Generate new API key
5. Copy and paste the key here
6. Test connection before saving`,
      required: true
    }
  ]);

  const testConnection = async (id: string) => {
    try {
      // Implement actual API testing logic here
      const api = apiKeys.find(key => key.id === id);
      if (!api?.key) {
        throw new Error('API key is required');
      }

      // Simulate API test
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setApiKeys(keys => 
        keys.map(key => 
          key.id === id 
            ? { ...key, status: 'connected', lastChecked: new Date().toISOString() }
            : key
        )
      );
      
      toast.success(`Successfully connected to ${api.name}`);
    } catch (error) {
      setApiKeys(keys => 
        keys.map(key => 
          key.id === id 
            ? { ...key, status: 'error', lastChecked: new Date().toISOString() }
            : key
        )
      );
      toast.error(`Failed to connect to API: ${error.message}`);
    }
  };

  const handleSave = async (id: string, value: string) => {
    setApiKeys(keys => 
      keys.map(key => 
        key.id === id ? { ...key, key: value } : key
      )
    );
    await testConnection(id);
  };

  const getStatusBadge = (status: ApiKey['status']) => {
    const styles = {
      connected: 'bg-green-100 text-green-800',
      disconnected: 'bg-gray-100 text-gray-800',
      error: 'bg-red-100 text-red-800'
    };

    return (
      <span className={`px-2 py-1 rounded-full text-sm ${styles[status]}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold">API Configuration</h1>
          <p className="text-gray-600 mt-2">
            Configure and manage all API integrations for your application
          </p>
        </div>
        <button
          onClick={() => apiKeys.forEach(api => testConnection(api.id))}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          Test All Connections
        </button>
      </div>
      
      <div className="space-y-8">
        {apiKeys.map((api) => (
          <div key={api.id} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h2 className="text-xl font-semibold">{api.name}</h2>
                <div className="flex items-center gap-3 mt-1">
                  {getStatusBadge(api.status)}
                  <span className="text-sm text-gray-500">
                    Last checked: {new Date(api.lastChecked).toLocaleString()}
                  </span>
                </div>
              </div>
              <a
                href={api.docsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-indigo-600 hover:text-indigo-800"
              >
                <ExternalLink className="w-4 h-4 mr-1" />
                Documentation
              </a>
            </div>
            
            <p className="text-gray-600 mb-4">
              {api.description}
              {api.required && (
                <span className="text-red-600 ml-1">Required for operation.</span>
              )}
            </p>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                API Key
              </label>
              <div className="flex gap-2">
                <input
                  type="password"
                  className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  value={api.key}
                  onChange={(e) => handleSave(api.id, e.target.value)}
                  placeholder={`Enter ${api.name} key`}
                />
                <button
                  onClick={() => testConnection(api.id)}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
                >
                  <Check className="w-4 h-4 mr-2" />
                  Test Connection
                </button>
              </div>
            </div>
            
            <div className="bg-gray-50 p-4 rounded-md">
              <div className="flex items-start gap-2">
                <AlertCircle className="w-5 h-5 text-gray-600 mt-0.5" />
                <div>
                  <h3 className="font-medium mb-2">Setup Guide</h3>
                  <pre className="text-sm text-gray-600 whitespace-pre-wrap">
                    {api.setupGuide}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ApiManager;