import { User } from '../types/user';
import { db } from '../config/firebase';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { hashIpAddress } from '../utils/security';

export class UserService {
  private static instance: UserService;
  
  private constructor() {}

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public async createUser(data: Partial<User>): Promise<User> {
    try {
      if (!data.id || !data.email) {
        throw new Error('User ID and email are required');
      }

      // Hash IP address for security
      const hashedIp = data.ipAddress ? await hashIpAddress(data.ipAddress) : '';

      const user: User = {
        id: data.id,
        name: data.name || '',
        email: data.email,
        status: data.status || 'active',
        plan: data.plan || 'free',
        role: data.role || 'user',
        credits: data.credits || 20,
        joinedDate: data.joinedDate || new Date().toISOString(),
        videosCreated: data.videosCreated || 0,
        ipAddress: hashedIp,
        lastAccessed: new Date().toISOString()
      };

      const userRef = doc(db, 'users', user.id);
      await setDoc(userRef, user);

      // Remove sensitive data before returning
      const { ipAddress, ...safeUser } = user;
      return safeUser as User;
    } catch (error) {
      console.error('Error creating user:', error);
      throw new Error('Failed to create user');
    }
  }

  public async getUser(userId: string): Promise<User | null> {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        return null;
      }

      const userData = userDoc.data() as User;

      // Update last accessed timestamp
      await updateDoc(userRef, {
        lastAccessed: new Date().toISOString()
      });

      // Remove sensitive data before returning
      const { ipAddress, ...safeUser } = userData;
      return safeUser as User;
    } catch (error) {
      console.error('Error fetching user:', error);
      throw new Error('Failed to fetch user');
    }
  }

  public async updateUser(userId: string, updates: Partial<User>): Promise<User> {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      // Remove sensitive fields from updates
      const { id, ipAddress, joinedDate, ...safeUpdates } = updates;

      await updateDoc(userRef, {
        ...safeUpdates,
        lastAccessed: new Date().toISOString()
      });

      return this.getUser(userId) as Promise<User>;
    } catch (error) {
      console.error('Error updating user:', error);
      throw new Error('Failed to update user');
    }
  }

  public async deactivateUser(userId: string): Promise<void> {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        status: 'inactive',
        lastAccessed: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error deactivating user:', error);
      throw new Error('Failed to deactivate user');
    }
  }

  public async addCredits(userId: string, amount: number): Promise<User> {
    try {
      const user = await this.getUser(userId);
      if (!user) throw new Error('User not found');

      const updatedUser = await this.updateUser(userId, {
        credits: user.credits + amount
      });

      return updatedUser;
    } catch (error) {
      console.error('Error adding credits:', error);
      throw new Error('Failed to add credits');
    }
  }

  public async deductCredits(userId: string, amount: number): Promise<User> {
    try {
      const user = await this.getUser(userId);
      if (!user) throw new Error('User not found');
      
      if (user.credits < amount) {
        throw new Error('Insufficient credits');
      }

      const updatedUser = await this.updateUser(userId, {
        credits: user.credits - amount
      });

      return updatedUser;
    } catch (error) {
      console.error('Error deducting credits:', error);
      throw new Error('Failed to deduct credits');
    }
  }
}

export const userService = UserService.getInstance();