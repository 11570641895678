import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import ScriptCreation from '../components/video/ScriptCreation';
import VoiceSelection from '../components/video/VoiceSelection';
import AspectRatioSelector from '../components/video/AspectRatioSelector';
import ImageStyleSelector from '../components/video/ImageStyleSelector';
import VideoPreview from '../components/video/VideoPreview';
import { AlertCircle } from 'lucide-react';

type Step = 'script' | 'voice' | 'aspect' | 'style' | 'preview';

interface VideoConfig {
  script: string;
  scriptSource: 'ai' | 'url' | 'manual';
  voiceId: string;
  aspectRatio: '9:16' | '16:9' | '1:1';
  imageStyle: string;
}

function CreateVideo() {
  const { userData } = useAuth();
  const [currentStep, setCurrentStep] = useState<Step>('script');
  const [config, setConfig] = useState<VideoConfig>({
    script: '',
    scriptSource: 'manual',
    voiceId: '',
    aspectRatio: '9:16',
    imageStyle: ''
  });
  const [generating, setGenerating] = useState(false);

  const handleScriptComplete = (script: string, source: 'ai' | 'url' | 'manual') => {
    setConfig({ ...config, script, scriptSource: source });
    setCurrentStep('voice');
  };

  const handleVoiceSelect = (voiceId: string) => {
    setConfig({ ...config, voiceId });
    setCurrentStep('aspect');
  };

  const handleAspectSelect = (aspectRatio: '9:16' | '16:9' | '1:1') => {
    setConfig({ ...config, aspectRatio });
    setCurrentStep('style');
  };

  const handleStyleSelect = (imageStyle: string) => {
    setConfig({ ...config, imageStyle });
    setCurrentStep('preview');
  };

  const handleGenerateVideo = async () => {
    if (userData?.credits && userData.credits < 10) {
      toast.error('Not enough credits. Please purchase more credits to continue.');
      return;
    }

    setGenerating(true);
    try {
      // Implement video generation logic
      await videoService.generateVideo(config);
      toast.success('Video generated successfully!');
    } catch (error) {
      toast.error('Failed to generate video. Please try again.');
    } finally {
      setGenerating(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'script':
        return <ScriptCreation onComplete={handleScriptComplete} />;
      case 'voice':
        return <VoiceSelection onSelect={handleVoiceSelect} script={config.script} />;
      case 'aspect':
        return <AspectRatioSelector onSelect={handleAspectSelect} />;
      case 'style':
        return <ImageStyleSelector onSelect={handleStyleSelect} />;
      case 'preview':
        return (
          <VideoPreview
            config={config}
            onGenerate={handleGenerateVideo}
            generating={generating}
          />
        );
    }
  };

  const steps = [
    { key: 'script', label: 'Create Script' },
    { key: 'voice', label: 'Select Voice' },
    { key: 'aspect', label: 'Aspect Ratio' },
    { key: 'style', label: 'Visual Style' },
    { key: 'preview', label: 'Generate' }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-4">Create New Video</h1>
        
        {/* Progress Steps */}
        <div className="flex justify-between mb-8">
          {steps.map((step, index) => (
            <div
              key={step.key}
              className={`flex items-center ${
                index < steps.findIndex(s => s.key === currentStep)
                  ? 'text-green-600'
                  : index === steps.findIndex(s => s.key === currentStep)
                  ? 'text-indigo-600'
                  : 'text-gray-400'
              }`}
            >
              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                index < steps.findIndex(s => s.key === currentStep)
                  ? 'bg-green-100'
                  : index === steps.findIndex(s => s.key === currentStep)
                  ? 'bg-indigo-100'
                  : 'bg-gray-100'
              }`}>
                {index + 1}
              </div>
              <span className="ml-2 text-sm font-medium">{step.label}</span>
              {index < steps.length - 1 && (
                <div className="w-12 h-px bg-gray-200 mx-2" />
              )}
            </div>
          ))}
        </div>

        {/* Credits Warning */}
        {userData?.credits < 10 && (
          <div className="bg-yellow-50 border border-yellow-200 rounded-md p-4 mb-6">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-yellow-400" />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Low Credits Warning
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    You need 10 credits to generate a video. You currently have {userData.credits} credits.
                    <a href="/dashboard/billing" className="ml-1 font-medium text-yellow-800 underline">
                      Purchase more credits
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="bg-white rounded-lg shadow-md p-6">
          {renderStep()}
        </div>
      </div>
    </div>
  );
}

export default CreateVideo;