import React, { useState } from 'react';

interface ImageStyleSelectorProps {
  onSelect: (style: string) => void;
}

const AI_STYLES = [
  { id: 'old-film', name: 'Old Film', description: 'Vintage cinema look with grain and warmth' },
  { id: '3d-model', name: '3D Models', description: 'Professional 3D rendered scenes' },
  { id: 'realistic', name: 'Realistic', description: 'Photorealistic images with high detail' },
  { id: 'cartoon', name: 'Cartoon', description: 'Fun and playful cartoon style' },
  { id: 'watercolor', name: 'Watercolor', description: 'Artistic watercolor painting effect' },
  { id: 'black-white', name: 'Black & White', description: 'Classic monochrome photography' },
  { id: 'spooky', name: 'Spooky', description: 'Dark and mysterious atmosphere' },
  { id: 'colorful', name: 'Bright & Colorful', description: 'Vibrant and energetic colors' },
  { id: 'gothic', name: 'Gothic', description: 'Dark and dramatic gothic style' },
  { id: 'anime', name: 'Anime', description: 'Japanese anime art style' },
  { id: 'fantasy', name: 'Fantasy Artwork', description: 'Magical and fantastical scenes' },
  { id: 'neon-punk', name: 'Neon Punk', description: 'Cyberpunk with bright neon colors' },
  // Add more styles as needed
];

export default function ImageStyleSelector({ onSelect }: ImageStyleSelectorProps) {
  const [selectedStyle, setSelectedStyle] = useState<string>('');

  const handleContinue = () => {
    if (!selectedStyle) {
      toast.error('Please select a visual style');
      return;
    }
    onSelect(selectedStyle);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold mb-4">Choose Visual Style</h2>
      
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {AI_STYLES.map((style) => (
          <div
            key={style.id}
            onClick={() => setSelectedStyle(style.id)}
            className={`p-4 rounded-lg border-2 cursor-pointer ${
              selectedStyle === style.id
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-200'
            }`}
          >
            <h3 className="font-medium mb-1">{style.name}</h3>
            <p className="text-sm text-gray-500">{style.description}</p>
          </div>
        ))}
      </div>

      <button
        onClick={handleContinue}
        className="w-full py-2 px-4 bg-green-600 text-white rounded-md hover:bg-green-700"
      >
        Generate Video (10 Credits)
      </button>
    </div>
  );
}