import React, { createContext, useContext, useState, useEffect } from 'react';
import { BrowserCache } from '../services/cache/BrowserCache';

interface PaymentSettings {
  cryptoPaymentsEnabled: boolean;
}

interface PaymentContextType {
  settings: PaymentSettings;
  updateSettings: (newSettings: Partial<PaymentSettings>) => Promise<void>;
  isCryptoEnabled: () => boolean;
}

const PaymentContext = createContext<PaymentContextType>({
  settings: { cryptoPaymentsEnabled: false },
  updateSettings: async () => {},
  isCryptoEnabled: () => false
});

export const usePayment = () => useContext(PaymentContext);

export const PaymentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [settings, setSettings] = useState<PaymentSettings>({
    cryptoPaymentsEnabled: false
  });
  const cache = BrowserCache.getInstance();

  useEffect(() => {
    const loadSettings = async () => {
      const savedSettings = await cache.get('payment_settings');
      if (savedSettings) {
        setSettings(JSON.parse(savedSettings));
      }
    };
    loadSettings();
  }, []);

  const updateSettings = async (newSettings: Partial<PaymentSettings>) => {
    const updatedSettings = { ...settings, ...newSettings };
    setSettings(updatedSettings);
    await cache.set('payment_settings', JSON.stringify(updatedSettings), 86400); // 24 hours TTL
  };

  const isCryptoEnabled = () => settings.cryptoPaymentsEnabled;

  return (
    <PaymentContext.Provider value={{ settings, updateSettings, isCryptoEnabled }}>
      {children}
    </PaymentContext.Provider>
  );
};