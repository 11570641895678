import React from 'react';
import { 
  Wand2, 
  Video, 
  Zap, 
  Shield, 
  Globe2, 
  Sparkles,
  PenTool,
  Palette,
  Music,
  Languages,
  CloudLightning,
  Lock
} from 'lucide-react';
import { Link } from 'react-router-dom';

function Features() {
  const features = [
    {
      icon: <Wand2 className="w-8 h-8 text-indigo-600" />,
      title: "AI-Powered Script Generation",
      description: "Transform any idea or URL into engaging video scripts instantly. Our advanced AI understands context and creates compelling narratives optimized for short-form content."
    },
    {
      icon: <Video className="w-8 h-8 text-indigo-600" />,
      title: "Multi-Format Video Creation",
      description: "Create videos in any aspect ratio (9:16, 16:9, or 1:1) perfect for TikTok, Instagram Reels, YouTube Shorts, and more. Export in up to 4K quality."
    },
    {
      icon: <Sparkles className="w-8 h-8 text-indigo-600" />,
      title: "Premium Visual Styles",
      description: "Choose from multiple AI-generated visual styles including realistic, cartoon, 3D models, watercolor, and more. Each frame is uniquely crafted to match your content."
    },
    {
      icon: <PenTool className="w-8 h-8 text-indigo-600" />,
      title: "Advanced Text-to-Speech",
      description: "Professional voice-overs in multiple languages and accents. Natural-sounding voices with adjustable speed, pitch, and emphasis."
    },
    {
      icon: <Music className="w-8 h-8 text-indigo-600" />,
      title: "Copyright-Free Assets",
      description: "Access our extensive library of copyright-free music, sound effects, and visuals. Never worry about content claims or licensing issues."
    },
    {
      icon: <CloudLightning className="w-8 h-8 text-indigo-600" />,
      title: "Lightning-Fast Processing",
      description: "Generate professional videos in minutes, not hours. Our optimized cloud infrastructure ensures rapid rendering and delivery."
    },
    {
      icon: <Globe2 className="w-8 h-8 text-indigo-600" />,
      title: "URL Content Import",
      description: "Instantly convert blog posts, articles, or any web content into engaging videos. Our AI intelligently extracts and adapts content for video format."
    },
    {
      icon: <Palette className="w-8 h-8 text-indigo-600" />,
      title: "Custom Branding",
      description: "Add your logo, custom colors, and brand elements. Create consistent, branded content that stands out on social media."
    },
    {
      icon: <Languages className="w-8 h-8 text-indigo-600" />,
      title: "Multi-Language Support",
      description: "Create videos in multiple languages with native-speaking voice-overs. Reach global audiences with localized content."
    },
    {
      icon: <Lock className="w-8 h-8 text-indigo-600" />,
      title: "Secure Cloud Storage",
      description: "All your videos and assets are securely stored in the cloud. Access your content anywhere, anytime."
    },
    {
      icon: <Shield className="w-8 h-8 text-indigo-600" />,
      title: "Commercial Usage Rights",
      description: "All generated content is cleared for commercial use. Use your videos for business, marketing, or monetization without restrictions."
    },
    {
      icon: <Zap className="w-8 h-8 text-indigo-600" />,
      title: "Flexible Credit System",
      description: "Pay only for what you use with our credit system. Purchase credits in bulk for better value or subscribe for unlimited access."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-br from-indigo-600 to-purple-700 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              Create Viral Videos in Minutes
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-indigo-100">
              Professional short-form videos powered by AI. No editing skills required.
            </p>
            <Link
              to="/register"
              className="inline-flex items-center px-8 py-3 border border-transparent text-lg font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
            >
              Start Creating Free
            </Link>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-indigo-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Ready to Create Amazing Videos?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Get 20 free credits when you sign up today. No credit card required.
            </p>
            <div className="space-x-4">
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Start Free Trial
              </Link>
              <Link
                to="/pricing"
                className="inline-flex items-center px-6 py-3 border border-indigo-600 text-base font-medium rounded-md text-indigo-600 bg-transparent hover:bg-indigo-50"
              >
                View Pricing
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;