import React, { useState } from 'react';
import { CreditCard } from 'lucide-react';
import toast from 'react-hot-toast';

interface CreditPurchaseProps {
  currentCredits: number;
  onPurchase: (amount: number) => void;
}

const CreditPurchase: React.FC<CreditPurchaseProps> = ({ currentCredits, onPurchase }) => {
  const [selectedAmount, setSelectedAmount] = useState(120);

  const creditPackages = [
    { credits: 120, price: 10 },
    { credits: 300, price: 25 },
    { credits: 600, price: 45 }
  ];

  const handlePurchase = () => {
    // Implement purchase logic
    toast.success(`Successfully purchased ${selectedAmount} credits`);
    onPurchase(selectedAmount);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">Purchase Additional Credits</h2>
      <p className="text-gray-600 mb-4">
        Current Balance: {currentCredits} credits
      </p>

      <div className="space-y-4 mb-6">
        {creditPackages.map((pkg) => (
          <div
            key={pkg.credits}
            onClick={() => setSelectedAmount(pkg.credits)}
            className={`p-4 border rounded-lg cursor-pointer transition-colors ${
              selectedAmount === pkg.credits
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-200 hover:border-indigo-300'
            }`}
          >
            <div className="flex justify-between items-center">
              <div>
                <p className="font-semibold">{pkg.credits} Credits</p>
                <p className="text-sm text-gray-500">
                  ${pkg.price} (${(pkg.price / pkg.credits * 100).toFixed(2)} per credit)
                </p>
              </div>
              <div className="text-xl font-bold text-indigo-600">
                ${pkg.price}
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={handlePurchase}
        className="w-full flex items-center justify-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
      >
        <CreditCard className="w-5 h-5 mr-2" />
        Purchase Credits
      </button>
    </div>
  );
};

export default CreditPurchase;