import React from 'react';

interface AdminChartProps {
  type: 'line' | 'bar';
}

export default function AdminChart({ type }: AdminChartProps) {
  // This is a placeholder component - in a real application,
  // you would integrate with a charting library like Chart.js or Recharts
  return (
    <div className="h-64 flex items-center justify-center bg-gray-50 rounded">
      <p className="text-gray-500">Chart placeholder ({type} chart)</p>
    </div>
  );
}